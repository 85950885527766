import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Grid, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';

import {
  FlexRow,
  errorColor,
  errorColorLightened,
  primaryColor1,
  primaryColor2,
  primaryColor2Darkened,
} from '../styles/constants';
import { RootState } from '../store';
import { LoadingSpinner, Success } from '../components';
import { addWaitlistUserRequest } from '../store/actions/waitlistActions';
import { useNavigate } from 'react-router-dom';

const StyledGrid = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr auto 2fr;
  grid-gap: 2rem;
  margin: 2rem 0;
`;

const SaveButton = styled(Button)({
  backgroundColor: primaryColor2,
  color: 'white',
  '&:hover': {
    backgroundColor: primaryColor2Darkened,
  },
});

const CancelButton = styled(Button)({
  backgroundColor: errorColor,
  color: 'white',
  '&:hover': {
    backgroundColor: errorColorLightened,
  },
});

const validationSchema = yup.object({
  reason: yup.string().required('Required field.'),
});

interface JoinWaitlistFormProps {
  productId: string | undefined;
  productLabel: string | undefined;
}

const JoinWaitlistForm = ({
  productId,
  productLabel,
}: JoinWaitlistFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addWaitlistUser } = useSelector(
    (state: RootState) => state.waitlists
  );

  useEffect(() => {
    if (addWaitlistUser.success === true) {
      navigate('/create-translation/success');
    }
  }, [addWaitlistUser.success, navigate]);

  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (productId) {
        dispatch(
          addWaitlistUserRequest(parseInt(productId, 10), values.reason)
        );
      }
    },
  });

  console.log('Formik Errors:', formik.errors);

  const renderContent = () =>
    addWaitlistUser.success === true ? (
      <FlexRow addStyles='height: 85vh;'>
        <Success message='Waitlist joined!' />
      </FlexRow>
    ) : (
      <form style={{ padding: '0.25rem' }} onSubmit={formik.handleSubmit}>
        <StyledGrid>
          <Typography
            sx={{ fontSize: '1.25rem' }}
            variant='body1'
            color={primaryColor1}
          >
            Tell us a little about yourself and why you're interested in our{' '}
            {productLabel} product, and we'll keep you updated on our product
            development!
          </Typography>

          <TextField
            id='reason'
            name='reason'
            multiline
            variant='outlined'
            fullWidth
            placeholder='Your position, company, budget, goals, etc. The more you include the better!'
            rows={20}
            value={formik.values.reason}
            onChange={formik.handleChange}
            error={formik.touched.reason && Boolean(formik.errors.reason)}
            helperText={formik.touched.reason && formik.errors.reason}
          />

          <FlexRow addStyles='justify-content: flex-start; align-items: flex-start;'>
            <CancelButton
              onClick={() => navigate('/create-translation/success')}
            >
              Cancel
            </CancelButton>
            <SaveButton style={{ marginLeft: '0.5rem' }} type='submit'>
              Save
            </SaveButton>
          </FlexRow>
        </StyledGrid>

        {addWaitlistUser.success === false && (
          <Typography
            sx={{ fontSize: '1.25rem' }}
            variant='body1'
            color={errorColor}
          >
            Oops! Something went wrong, please try again or email us via our
            contact form.
          </Typography>
        )}
      </form>
    );

  return addWaitlistUser.loading ? (
    <FlexRow addStyles='height: 85vh;'>
      <LoadingSpinner size='l' />
    </FlexRow>
  ) : (
    renderContent()
  );
};

export default JoinWaitlistForm;
