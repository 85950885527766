import { Box, Grid, Typography, Link } from '@mui/material';
import { Email } from '@mui/icons-material';
import styled from '@emotion/styled';

import {
  primaryColor1,
  backgroundColor1,
  maxPageWidth,
  responsiveMaxPageWidth,
} from '../styles/constants';
import { useTranslation } from 'react-i18next';

const StyledFooterContainer = styled.footer`
  width: 100%;
  background-color: ${primaryColor1};
  color: ${backgroundColor1};
  display: flex;
  justify-content: center;
`;

const StyledFooterContent = styled.div`
  width: ${maxPageWidth}px;

  ${responsiveMaxPageWidth}
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <StyledFooterContainer>
      <StyledFooterContent>
        <Box
          bgcolor={primaryColor1}
          color='white'
          px={{ xs: 3, sm: 10 }}
          py={{ xs: 5, sm: 10 }}
        >
          <Grid container spacing={3} justifyContent='space-between'>
            <Grid item xs={12} lg={3}>
              <Typography variant='h6'>{t('Service')}</Typography>
              <Typography sx={{ paddingTop: '0.25rem' }} variant='subtitle1'>
                {t('Trash Bandit Translations')}
              </Typography>
              <Typography sx={{ paddingTop: '0.25rem' }} variant='body2'>
                {t(
                  'Democratizing access to document translations through the power of AI, making it a cost-effective reality for everyone.'
                )}
              </Typography>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography variant='h6'>{t('Contact')}</Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Email />{' '}
                <div style={{ marginLeft: '0.5rem' }}>
                  <Link href='/contact-us' color='inherit' variant='body2'>
                    {t('Contact Us')}
                  </Link>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Typography variant='h6'>{t('Links')}</Typography>
              <div style={{ paddingTop: '0.5rem' }}>
                <Link href='/faq' color='inherit' variant='body2'>
                  {t('Faq')}
                </Link>
              </div>

              <div style={{ paddingTop: '0.5rem' }}>
                <Link href='/privacy-policy' color='inherit' variant='body2'>
                  {t('Privacy Policy')}
                </Link>
              </div>

              <div style={{ paddingTop: '0.5rem' }}>
                <Link href='/terms-of-service' color='inherit' variant='body2'>
                  {t('Terms of Service')}
                </Link>
              </div>
            </Grid>

            {/* Social Media Icons */}
            {/* Legal Information */}
          </Grid>
        </Box>
      </StyledFooterContent>
    </StyledFooterContainer>
  );
};

export default Footer;
