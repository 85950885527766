import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';

import { FlexRow, primaryColor1 } from '../styles/constants';

const Success = ({
  message,
  color,
  variant,
  addStyles,
}: {
  message: string;
  color?: string;
  variant?: any;
  addStyles?: string;
}) => (
  <FlexRow addStyles={addStyles}>
    <Typography
      variant={variant ? variant : 'h4'}
      color={color ? color : primaryColor1}
    >
      {message}
    </Typography>{' '}
    <CheckCircleIcon style={{ color: green[500], marginLeft: '1rem' }} />
  </FlexRow>
);

export default Success;
