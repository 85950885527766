import api from '../../utils/api';

export const getFutureProducts = () => {
  return api.get('/future-products');
};

export const getWaitlistUsers = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.get('/waitlist-users');
};

export const createWaitlistUser = (
  productId: number,
  reason: string,
  token: string
) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.post('/waitlist-user', { productId, reason });
};
