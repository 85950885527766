import { useTranslation } from 'react-i18next';
import React, { MouseEventHandler, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';

import StripeCard from './StripeCard';
import AddressForm from '../../forms/AddressForm';
import { RootState } from '../../store';
import { BillingAddress } from '../../store/models/UserModels';
import {
  FlexColumn,
  FlexRow,
  primaryColor2,
  textColorSecondary,
} from '../../styles/constants';
import CheckoutSummary from './CheckoutSummary';
import { LoadingSpinner } from '../../components';
import { createTranslationPaymentRequest } from '../../store/actions/translationActions';

interface PaymentInfoProps {
  completeStep: () => void;
  goBackToQuoteStep: () => void;
}

const StyledContainer = styled.div`
  width: 100%;
  align-self: flex-start;
`;

const EditButton = styled(IconButton)`
  margin-left: 8px;
`;

interface DisplayBillingAddressProps {
  billingAddress: BillingAddress;
  onClick: MouseEventHandler<HTMLButtonElement>;
  t: any;
}

const DisplayBillingAddress = ({
  billingAddress,
  onClick,
  t,
}: DisplayBillingAddressProps) => {
  return (
    <div style={{ padding: '1rem' }}>
      <FlexColumn addStyles='align-items: flex-start;'>
        <FlexRow addStyles='width: 100%; justify-content: flex-start;'>
          <Typography variant='h6'>{t('Billing Address')}</Typography>
          <EditButton
            sx={{ color: primaryColor2, marginLeft: '2rem !important' }}
            aria-label='edit billing address'
            size='small'
            onClick={onClick}
          >
            <EditIcon />
          </EditButton>
        </FlexRow>
        <Typography variant='body1' sx={{ color: textColorSecondary }}>
          {billingAddress.street}
        </Typography>
        <Typography variant='body1' sx={{ color: textColorSecondary }}>
          {billingAddress.city}, {billingAddress.region},{' '}
          {billingAddress.postalCode}
        </Typography>
        <Typography variant='body1' sx={{ color: textColorSecondary }}>
          {billingAddress.country}
        </Typography>
      </FlexColumn>
    </div>
  );
};

const PaymentInfo = ({ completeStep, goBackToQuoteStep }: PaymentInfoProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { translationPayment, translationQuote, createTranslationPayment } =
    useSelector((state: RootState) => state.translations);
  const [isEditingBillingAddress, setIsEditingBillingAddress] =
    React.useState<boolean>(false);

  const handleEditClick = () => {
    setIsEditingBillingAddress((prevState) => !prevState);
  };

  useEffect(() => {
    if (translationQuote?.billingAddress) {
      dispatch(createTranslationPaymentRequest(translationQuote.id));
    }
  }, [dispatch, translationQuote]);

  const renderContent = () => {
    if (
      createTranslationPayment.loading ||
      (translationQuote?.billingAddress &&
        !translationPayment &&
        createTranslationPayment.success === undefined)
    ) {
      return <LoadingSpinner />;
    } else if (createTranslationPayment.success === false) {
      return (
        <Typography variant='h6' sx={{ color: 'red' }}>
          {t('Oops! Something went wrong.')}
        </Typography>
      );
    }

    return (
      <StyledContainer>
        {!translationQuote?.billingAddress || isEditingBillingAddress ? (
          <div style={{ margin: '1rem' }}>
            <AddressForm
              t={t}
              quoteId={translationQuote?.id!}
              setIsEditingBillingAddress={setIsEditingBillingAddress}
              goBackToQuoteStep={goBackToQuoteStep}
              translationQuoteBillingAddress={translationQuote?.billingAddress}
            />
          </div>
        ) : (
          <>
            <DisplayBillingAddress
              billingAddress={translationQuote.billingAddress}
              onClick={handleEditClick}
              t={t}
            />

            <Divider orientation='horizontal' />

            <CheckoutSummary
              price={translationQuote!.price}
              total={translationPayment!.amount}
              taxRates={translationPayment!.taxRates}
              currency={translationQuote!.pagePrice.currency.value}
            />

            <Divider orientation='horizontal' />

            <StripeCard t={t} completeStep={completeStep} />
          </>
        )}
      </StyledContainer>
    );
  };

  return renderContent();
};

export default PaymentInfo;
