interface EventData {
  eventId: string;
  timestamp: string;
}

// Extend window interface for TypeScript to recognize 'fbq'
declare global {
  interface Window {
    fbq: (action: string, eventName: string, params?: any) => void;
  }
}

// Function to generate a unique event ID
const generateUniqueID = (): string =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

// Function to check if the stored event ID is still valid
const isEventIdValid = (eventIdData: string): boolean => {
  if (!eventIdData) {
    return false;
  }

  const data: EventData = JSON.parse(eventIdData);
  const storedDate: Date = new Date(data.timestamp);
  const currentDate: Date = new Date();
  const differenceInDays: number =
    (currentDate.getTime() - storedDate.getTime()) / (1000 * 3600 * 24);

  return differenceInDays <= 7;
};

// Function to get or create an event ID
const getOrCreateEventId = (eventName: string): string => {
  const eventIdData: string | null = localStorage.getItem(eventName);

  if (eventIdData && isEventIdValid(eventIdData)) {
    return JSON.parse(eventIdData).eventId;
  } else {
    const newEventId: string = generateUniqueID();
    const eventIdObject: EventData = {
      eventId: newEventId,
      timestamp: new Date().toISOString(),
    };

    localStorage.setItem(eventName, JSON.stringify(eventIdObject));
    return newEventId;
  }
};

// Utility function to track events using Facebook Pixel
const trackFBEvent = (eventName: string, params: any): void => {
  console.log('trackFBEvent() is called', eventName, params);
  const fbclid: string | null = localStorage.getItem('fbclid');
  console.log('fbclid:', fbclid);
  //  if (process.env.NODE_ENV === 'production' && fbclid) {
  if (process.env.NODE_ENV === 'production') {
    console.log('Tracking event:', eventName, params);
    window.fbq('track', eventName, params);
  }
};

const viewContent = (): void => {
  console.log('ViewContent() is callecd');
  const eventId: string = getOrCreateEventId('ViewContentEvent');
  const eventTime: number = Math.floor(Date.now() / 1000);

  const params = {
    action_source: 'website',
    event_id: eventId,
    event_name: 'ViewContent',
    event_source_url: window.location.href,
    event_time: eventTime,
    client_user_agent: navigator.userAgent,
  };

  console.log('params:', params);
  trackFBEvent('ViewContent', params);
};

export { trackFBEvent, viewContent };
