import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';

import JoinWaitlistForm from '../../forms/JoinWaitlistForm';
import { maxPageWidth, responsiveMaxPageWidth } from '../../styles/constants';

const StyledPageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledContentContainer = styled.div`
  width: 100%;
  max-width: ${maxPageWidth}px;

  ${responsiveMaxPageWidth}
`;

const JoinWaitlistPage = () => {
  const { productId, productLabel } = useParams();

  return (
    <StyledPageContainer>
      <StyledContentContainer>
        <JoinWaitlistForm productId={productId} productLabel={productLabel} />
      </StyledContentContainer>
    </StyledPageContainer>
  );
};

export default JoinWaitlistPage;
