import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { loginEnterpriseUserRequest } from 'src/store/actions/userActions';
import { useSelector } from 'react-redux';
import { FlexRow, AcceptButton, primaryColor1 } from '../../styles/constants';
import { Typography } from '@mui/material';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const StyledTextField = styled(TextField)`
  margin: 0.5rem 0;
`;

const StyledLink = styled(Link)`
  &:hover {
    opacity: 0.8;
  }
`;

const EnterpriseLoginForm = () => {
  const dispatch = useDispatch();
  const userLoginLoading = useSelector(
    (state: any) => state.user.login.loading
  );

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: ({ email, password }) => {
      console.log('submitting', email, password);
      dispatch(loginEnterpriseUserRequest({ email, password }));
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FlexRow>
          <Typography
            variant='h4'
            sx={{ color: primaryColor1, marginBottom: '2rem' }}
          >
            Enterprise Login
          </Typography>{' '}
        </FlexRow>
        <StyledTextField
          fullWidth
          id='email'
          name='email'
          label='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputLabelProps={{ shrink: true }}
        />
        <StyledTextField
          fullWidth
          id='password'
          name='password'
          label='Password'
          type='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputLabelProps={{ shrink: true }}
        />
        <AcceptButton
          disabled={userLoginLoading}
          variant='contained'
          fullWidth
          type='submit'
          data-testid='login-form-submit-button'
        >
          Submit
        </AcceptButton>
        <br />
        <br />
        {/* <FlexRow addStyles='justify-content: flex-end'>
          <StyledLink to='/forgot-password'>Forgot Password</StyledLink>
        </FlexRow> */}
      </form>
    </div>
  );
};

export default EnterpriseLoginForm;
