import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import QuoteCard from './QuoteCard';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../store';
import { AcceptButton, CancelButton, errorColor } from '../../styles/constants';
import { deleteTranslationQuoteRequest } from '../../store/actions/translationActions';
import { LoadingSpinner } from '../../components';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

interface ReviewQuoteProps {
  completeStep: () => void;
  setActiveStep: (step: number) => void;
  resetState: () => void;
}

const ReviewQuote = ({
  completeStep,
  setActiveStep,
  resetState,
}: ReviewQuoteProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    translationQuote,
    deleteTranslationQuote,
    createTranslationPayment,
    updateTranslationQuoteBillingAddress,
  } = useSelector((state: RootState) => state.translations);

  return deleteTranslationQuote.loading ||
    createTranslationPayment.loading ||
    updateTranslationQuoteBillingAddress.loading ? (
    <LoadingSpinner />
  ) : (
    <StyledContainer>
      <br />
      {translationQuote && deleteTranslationQuote.success !== false && (
        <>
          <QuoteCard quote={translationQuote} />
          <br />
          <br />
          <br />
          <ButtonRow>
            <CancelButton
              onClick={() => {
                if (translationQuote?.id) {
                  dispatch(deleteTranslationQuoteRequest(translationQuote.id));
                  setActiveStep(0);
                  resetState();
                }
              }}
            >
              {t('Cancel')}
            </CancelButton>
            <AcceptButton
              onClick={() => {
                completeStep();
              }}
            >
              {t('Accept')}
            </AcceptButton>
          </ButtonRow>
        </>
      )}
      {(!translationQuote || deleteTranslationQuote.success === false) && (
        <div style={{ color: errorColor }}>Oops! Something went wrong</div>
      )}
    </StyledContainer>
  );
};

export default ReviewQuote;
