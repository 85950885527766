import { useTranslation } from 'react-i18next';
import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MuiMobileStepper from '@mui/material/MobileStepper';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';

import {
  backgroundColor1,
  backgroundColor2,
  primaryColor1,
} from '../styles/constants';
import { Step } from '../pages/CreateTranslationPage/CreateTranslationPage';

interface MobileStepperProps {
  steps: Step[];
  setSteps: React.Dispatch<React.SetStateAction<Step[]>>;
  setTargetLanguageValue: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setStepsIsComplete: React.Dispatch<React.SetStateAction<boolean[]>>;
  activeStep: number;
  setActiveStep: (prevActiveStep: any) => void;
  navbarHeight: number;
  stepsIsComplete: boolean[];
}

interface StyledContainerProps {
  navbarHeight: number;
}

const StyledContainer = styled.div<StyledContainerProps>`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${(props) => props.navbarHeight}px);
  max-width: 1200px;
  flex-grow: 1;
  width: 100%;
`;

const StyledInnerBox = styled.div`
  background-color: ${backgroundColor2};
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: 1rem;
`;

interface StyledStepContainerProps {
  isWidth100: boolean;
}

const StyledStepContainer = styled.div<StyledStepContainerProps>`
  ${(props) => (props.isWidth100 ? 'width: 100%;' : '')}
`;

const MobileStepper = ({
  steps,
  setSteps,
  activeStep,
  setActiveStep,
  navbarHeight,
  stepsIsComplete,
}: MobileStepperProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  return (
    <StyledContainer navbarHeight={navbarHeight}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 50,
          pl: 2,
          pr: 2,
          bgcolor: primaryColor1,
          color: backgroundColor2,
        }}
      >
        <Typography>{steps[activeStep].title}</Typography>
      </Paper>
      <StyledInnerBox>
        {steps[activeStep].isShowDescription && (
          <>
            <div style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
              {steps[activeStep].description}
            </div>
            <br />
            <br />
          </>
        )}
        <StyledStepContainer
          isWidth100={!steps[activeStep].isContentCenteredHorizontally}
        >
          {steps[activeStep].component}
        </StyledStepContainer>
      </StyledInnerBox>
      <MuiMobileStepper
        variant='text'
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        sx={{ bgcolor: primaryColor1, color: backgroundColor2 }}
        nextButton={
          <Button
            sx={{ color: backgroundColor2 }}
            size='small'
            onClick={handleNext}
            disabled={!stepsIsComplete[activeStep]}
          >
            {t('Next')}
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button
            sx={{ color: backgroundColor1 }}
            size='small'
            onClick={handleBack}
            disabled={activeStep === 0 || activeStep === 2}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {t('Back')}
          </Button>
        }
      />
    </StyledContainer>
  );
};

export default MobileStepper;
