import { put, select, takeLatest } from 'redux-saga/effects';

import {
  TranslationActionTypes,
  fetchTargetLanguagesSuccess,
  createTranslationSuccess,
  resetTranslationState,
  TranslationAction,
  fetchTranslationsSuccess,
  createTranslationQuoteSuccess,
  createTranslationPaymentSuccess,
  getDeliveryEmailSuccess,
  getDeliveryEmailRequest,
  deleteStripePaymentSuccess,
  deleteStripePaymentFailure,
  deleteTranslationQuoteSuccess,
  deleteTranslationQuoteFailure,
  createTranslationPaymentFailure,
  updateTranslationPaymentStatusSuccess,
  updateTranslationPaymentStatusFailure,
  updateTranslationQuoteBillingAddressSuccess,
  createTranslationQuoteFailure,
} from '../actions/translationActions';

import {
  fetchTargetLanguages,
  createTranslation,
  fetchTranslations,
  createTranslationQuote,
  createOrUpdateDeliveryEmail,
  getDeliveryEmail,
  deleteStripePayment,
  deleteTranslationQuote,
  createTranslationPayment,
  updateTranslationPayment,
  updateTranslationQuoteBillingAddress,
} from '../api/translationApi';
import { callGenerator } from './sagaUtils';
import { ERROR_CREDENTIALS_OR_TOKEN_EXPIRED } from './constants';
import { RootState } from '..';
import { fetchUser } from '../actions/userActions';

function* fetchUserTranslationsSaga() {
  const token: string = yield select((state: RootState) => state.user.token);
  const userId: number = yield select(
    (state: RootState) => state.user.user?.id
  );

  try {
    const { data: translations } = yield* callGenerator(
      fetchTranslations,
      token,
      userId
    );
    yield put(fetchTranslationsSuccess(translations));
  } catch (error: any) {
    if (error.response.data) {
      if (error.response.data.detail) {
        const { detail } = error.response.data;

        if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
          yield put({ type: 'LOGOUT' });
        }
      }
    }
  }
}

function* fetchTargetLanguagesSaga() {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    const { data: targetLanguages } = yield* callGenerator(
      fetchTargetLanguages,
      token
    );
    yield put(fetchTargetLanguagesSuccess(targetLanguages));
  } catch (error: any) {
    if (error.response.data) {
      const { detail } = error.response.data;

      if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
        yield put({ type: 'LOGOUT' });
      }
    }
  }
}

function* createTranslationSaga(action: TranslationAction) {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    yield* callGenerator(createTranslation, action.payload, token);
    yield put(createTranslationSuccess());
    yield put({ type: 'NAVIGATE', payload: '/translate/my-translations' });
  } catch (error: any) {
    const { detail } = error.response.data;
    if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
      yield put({ type: 'LOGOUT' });
    }
  } finally {
    yield put(resetTranslationState());
  }
}

function* createTranslationQuoteSaga(action: TranslationAction) {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    const { data } = yield* callGenerator(
      createTranslationQuote,
      action.payload.formData,
      token
    );
    yield put(createTranslationQuoteSuccess(data.translationQuote));
  } catch (error: any) {
    let errorMessage = 'An error occurred';
    if (error.response?.data?.detail) {
      errorMessage = error.response.data.detail;
    }
    yield put(createTranslationQuoteFailure(errorMessage));
    // Error handling
    if (error.response?.data?.detail) {
      const { detail } = error.response.data;
      if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
        yield put({ type: 'LOGOUT' });
      }
    }
    // Optionally dispatch a failure action
  }
}

function* deleteTranslationQuoteSaga(action: TranslationAction) {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    yield* callGenerator(deleteTranslationQuote, action.payload, token);
    yield put(deleteTranslationQuoteSuccess());
  } catch (error: any) {
    // Error handling
    if (error.response?.data?.detail) {
      const { detail } = error.response.data;
      if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
        yield put({ type: 'LOGOUT' });
      }
    }
    yield put(deleteTranslationQuoteFailure());
  }
}

function* getTranslationDeliveryEmailSaga() {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    const { data: email } = yield* callGenerator(getDeliveryEmail, token);
    yield put(getDeliveryEmailSuccess(email));
  } catch (error: any) {
    // Error handling
    if (error.response?.data?.detail) {
      const { detail } = error.response.data;
      if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
        yield put({ type: 'LOGOUT' });
      }
    }
    // Optionally dispatch a failure action
  }
}

// TODO separate quote and payment
function* createTranslationDeliveryEmailSaga(action: TranslationAction) {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    yield* callGenerator(createOrUpdateDeliveryEmail, token, action.payload);
    yield put(getDeliveryEmailRequest());
  } catch (error: any) {
    // Error handling
    if (error.response?.data?.detail) {
      const { detail } = error.response.data;
      if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
        yield put({ type: 'LOGOUT' });
      }
    }
    // Optionally dispatch a failure action
  }
}

function* deleteStripePaymentSaga(action: TranslationAction) {
  const token: string = yield select((state) => state.user.token);

  try {
    yield* callGenerator(deleteStripePayment, action.payload.paymentId, token);
    yield put(deleteStripePaymentSuccess());
  } catch (error) {
    yield put(deleteStripePaymentFailure());
  }
}

function* createTranslationPaymentSaga(action: TranslationAction) {
  const token: string = yield select((state) => state.user.token);

  try {
    const { data } = yield* callGenerator(
      createTranslationPayment,
      action.payload,
      token
    );
    yield put(
      createTranslationPaymentSuccess(data.stripePayment, data.clientSecret)
    );
  } catch (error) {
    yield put(createTranslationPaymentFailure());
  }
}

function* updateTranslationQuoteBillingAddressSaga(action: TranslationAction) {
  const token: string = yield select((state: RootState) => state.user.token);
  try {
    const { data } = yield* callGenerator(
      updateTranslationQuoteBillingAddress,
      action.payload.payload,
      token
    );
    console.log('translationQuote', data.translationQuote);
    yield put(
      updateTranslationQuoteBillingAddressSuccess(data.translationQuote)
    );

    yield put(fetchUser());

    action.payload.callback(false);
  } catch (error: any) {
    console.log('ERROR', error);
  }
}

function* updateTranslationPaymentStatusSaga(action: TranslationAction) {
  const token: string = yield select((state) => state.user.token);

  try {
    yield* callGenerator(
      updateTranslationPayment,
      action.payload.paymentId,
      action.payload.paymentStatus,
      token
    );
    yield put(updateTranslationPaymentStatusSuccess());
    action.payload.callback();
  } catch (error) {
    yield put(updateTranslationPaymentStatusFailure());
  }
}

export default function* rootSaga(): Generator {
  yield takeLatest(
    TranslationActionTypes.FETCH_TARGET_LANGUAGES_REQUEST,
    fetchTargetLanguagesSaga
  );
  yield takeLatest(
    TranslationActionTypes.CREATE_TRANSLATION_REQUEST,
    createTranslationSaga
  );
  yield takeLatest(
    TranslationActionTypes.FETCH_TRANSLATIONS_REQUEST,
    fetchUserTranslationsSaga
  );
  yield takeLatest(
    TranslationActionTypes.CREATE_TRANSLATION_QUOTE_REQUEST,
    createTranslationQuoteSaga
  );
  yield takeLatest(
    TranslationActionTypes.CREATE_DELIVERY_EMAIL_REQUEST,
    createTranslationDeliveryEmailSaga
  );
  yield takeLatest(
    TranslationActionTypes.GET_DELIVERY_EMAIL_REQUEST,
    getTranslationDeliveryEmailSaga
  );
  yield takeLatest(
    TranslationActionTypes.DELETE_STRIPE_PAYMENT_REQUEST,
    deleteStripePaymentSaga
  );
  yield takeLatest(
    TranslationActionTypes.DELETE_TRANSLATION_QUOTE_REQUEST,
    deleteTranslationQuoteSaga
  );
  yield takeLatest(
    TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_REQUEST,
    createTranslationPaymentSaga
  );
  yield takeLatest(
    TranslationActionTypes.UPDATE_TRANSLATION_PAYMENT_STATUS,
    updateTranslationPaymentStatusSaga
  );

  yield takeLatest(
    TranslationActionTypes.UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST,
    updateTranslationQuoteBillingAddressSaga
  );
}
