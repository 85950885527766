import { Typography } from '@mui/material';
import { FlexRow, primaryColor1 } from 'src/styles/constants';

const ScheduledMaintenance = () => (
  <div>
    <br />
    <br />
    <br />
    <br />
    <FlexRow>
      <Typography sx={{ color: primaryColor1 }} variant='h2'>
        Scheduled Maintenance
      </Typography>
    </FlexRow>
    <br />
    <br />
    <FlexRow>
      <Typography sx={{ color: primaryColor1 }} variant='body1'>
        Trash Bandit Translations is currently undergoing scheduled maintenance.
        Please check back later.
      </Typography>
    </FlexRow>
  </div>
);

export default ScheduledMaintenance;
