import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import GoogleIcon from '@mui/icons-material/Google';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AnchorIcon from '@mui/icons-material/Anchor';
import AgricultureIcon from '@mui/icons-material/Agriculture';

import {
  backgroundColor1,
  backgroundColor2,
  maxPageWidth,
  primaryColor1,
  primaryColor2,
  primaryColor2Darkened,
  responsiveMaxPageWidth,
} from '../../styles/constants';
import Pricing from './Pricing';
import CallToAction from './CallToAction';
import Footer from '../../components/Footer';
import { createUniqueVisitor } from '../../store/api/userApi';
import { RootState } from '../../store';
import { checkAuthToken } from '../../store/actions/userActions';
import useWindowSize from '../../hooks/useScreenSize';
import Benefits from './Benefits';

interface StyledPageSectionContainerProps {
  backgroundColor: string;
  styleOverrides?: string;
  hasCurvedBackground?: boolean;
  secondColor?: string;
}

const StyledPageSectionContainer = styled.div<StyledPageSectionContainerProps>`
  margin-top: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  position relative;

  ${(props) => props.styleOverrides};
`;

const StyledPageSectionContent = styled.div`
  width: 100%;
  max-width: ${maxPageWidth}px;
  margin: 0 auto;
  padding: 1rem;

  ${responsiveMaxPageWidth}
`;

const tiers = [
  {
    emoji: '🔥',
    icon: <AnchorIcon sx={{ color: primaryColor2 }} fontSize='large' />,
    title: 'Standard',
    description: 'AI Analysis + Machine Translation',
    price: '2.95$ per page',
    perfectFor: [
      'When speed and price are paramount',
      'Great for higher page counts',
      'Ready in minutes',
    ],
  },
  {
    emoji: '😎',
    icon: <AgricultureIcon sx={{ color: primaryColor2 }} fontSize='large' />,
    title: 'Enterprise',
    description: 'For large volumes of documents',
    price: 'Contact us for pricing',
    perfectFor: [
      'Businesses with large translation needs',
      'Businesses providing translation services',
      'Translators wanting to triple their output',
    ],
  },
];

interface LandingPageProps {
  handleGoogleLogin: () => void;
}

const StyledHeroSectionContainer = styled.div`
  max-width: 900px;
  margin: auto;
  display: grid;
  grid-template-columns: 6fr 6fr;

  @media (max-width: 425px) {
    grid-template-columns: 1fr 0fr;
  }
`;

const StyledHeroDetails = styled.div`
  margin-top: 9rem;

  color: ${backgroundColor1};

  @media (max-width: 650px) {
    margin-top: 4rem;
  }
`;

const HeroSection = ({
  t,
  handleGoogleLogin,
}: {
  t: any;
  handleGoogleLogin: any;
}) => {
  const { user, token } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  let imageReductionFactor = 0.5;
  if (windowSize.width < 425) {
    imageReductionFactor = 0;
  } else if (windowSize.width < 470) {
    imageReductionFactor = 0.25;
  } else if (windowSize.width < 600) {
    imageReductionFactor = 0.35;
  }

  const signInMessage =
    windowSize.width < 620 ? 'SIGN IN' : 'SIGN IN WITH GOOGLE';

  const imageStyles = {
    maxWidth: `${720 * imageReductionFactor}px`,
    maxHeight: `${1280 * imageReductionFactor}px`,
  };

  return (
    <StyledHeroSectionContainer>
      <StyledHeroDetails>
        <Typography
          variant='h4'
          sx={{ fontWeight: 'bold', color: backgroundColor1 }}
        >
          {t('Translate your Documents with Ease using AI')}
        </Typography>
        <br />
        <br />
        <Typography variant='subtitle1'>
          {t(
            'The most sophisticated AI on the market can now assist with your translations.'
          )}
        </Typography>
        <br />
        <br />
        <br />
        <Button
          variant='contained'
          startIcon={user && token ? null : <GoogleIcon />}
          sx={{
            backgroundColor: primaryColor2,
            '&:hover': {
              backgroundColor: primaryColor2Darkened,
            },
            minWidth: 'fit-content',
          }}
          onClick={
            user && token
              ? () => navigate('/create-translation')
              : handleGoogleLogin
          }
        >
          {user && token ? t('Get Started') : t(signInMessage)}
        </Button>
      </StyledHeroDetails>
      <img
        src='./hero.png'
        style={{
          ...imageStyles,
          marginTop: windowSize.width < 470 ? '4rem' : '0',
        }}
        alt='A well dressed cyborg raccoon sits, reads and smokes a pipe'
      />
    </StyledHeroSectionContainer>
  );
};

const LandingPage = ({ handleGoogleLogin }: LandingPageProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log('url params', params);
    const fbclid = params.get('fbclid');
    console.log('fbclid', fbclid);
    if (fbclid) {
      localStorage.setItem('fbclid', fbclid);
    }

    createUniqueVisitor(fbclid);
  }, []);

  useEffect(() => {
    if (token) {
      dispatch(checkAuthToken({ token }));
    }
  }, [dispatch, token]);

  return (
    <>
      <StyledPageSectionContainer
        backgroundColor={backgroundColor1}
        styleOverrides={`padding-bottom: 0; margin-top: 0; margin-bottom: 0; background-color: ${primaryColor1}; border-bottom-right-radius: 400rem;`}
        hasCurvedBackground
        secondColor={backgroundColor2}
      >
        <StyledPageSectionContent>
          <HeroSection t={t} handleGoogleLogin={handleGoogleLogin} />
        </StyledPageSectionContent>
      </StyledPageSectionContainer>

      <StyledPageSectionContainer backgroundColor={backgroundColor2}>
        <StyledPageSectionContent>
          <Benefits />
        </StyledPageSectionContent>
      </StyledPageSectionContainer>

      <StyledPageSectionContainer backgroundColor={backgroundColor1}>
        <StyledPageSectionContent>
          <Pricing tiers={tiers} />
        </StyledPageSectionContent>
      </StyledPageSectionContainer>

      <StyledPageSectionContainer backgroundColor={backgroundColor2}>
        <StyledPageSectionContent>
          <CallToAction handleGoogleLogin={handleGoogleLogin} />
        </StyledPageSectionContent>
      </StyledPageSectionContainer>
      <Footer />
    </>
  );
};

export default LandingPage;
