import styled from '@emotion/styled';
import { Typography, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  AcceptButton,
  FlexRow,
  backgroundColor2,
  errorColor,
  maxPageWidth,
  primaryColor1,
  responsiveMaxPageWidth,
} from '../../styles/constants';
import { useDispatch, useSelector } from 'react-redux';
import { contactUsRequest } from '../../store/actions/userActions';
import { RootState } from '../../store';
import { LoadingSpinner, Success } from '../../components';
import { useScreenSize } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

interface StyledPageSectionContainerProps {
  backgroundColor: string;
  styleOverrides?: string;
}

const StyledPageSectionContainer = styled.div<StyledPageSectionContainerProps>`
  margin-top: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.styleOverrides};
`;

const StyledPageSectionContent = styled.div`
  width: 100%;
  max-width: ${maxPageWidth}px;
  margin: 0 auto;

  ${responsiveMaxPageWidth}
`;

const StyledSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Define our form validation schema using Yup
const ContactFormSchema = Yup.object().shape({
  message: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email format').required('Required'),
});

const ContactUsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userEmail = useSelector((state: RootState) => state.user.user?.email);
  const { loading, success } = useSelector(
    (state: RootState) => state.user.contactUs
  );
  const { width } = useScreenSize();

  useEffect(() => {
    return () => {
      // TODO fire an action creator function here so I don't need to hardcode the type
      dispatch({ type: 'CONTACT_US_RESET' });
    };
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      message: '',
      email: userEmail || '',
    },
    validationSchema: ContactFormSchema,
    onSubmit: (values) => {
      dispatch(contactUsRequest(values.email, values.message));
    },
  });

  const renderForm = () => (
    <>
      <Typography variant='h4' color={primaryColor1}>
        {t('Contact us')}
      </Typography>
      <br />
      <Typography variant='body1'>
        {t(
          'We are here to help. If you have any questions, concerns, comments or requests please feel free to reach out to us.'
        )}
      </Typography>

      <br />
      <br />

      <form onSubmit={formik.handleSubmit}>
        <TextField
          label={t('Email')}
          fullWidth
          variant='outlined'
          id='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <br />
        <br />

        <TextField
          label={t('Message')}
          fullWidth
          multiline
          rows={8}
          variant='outlined'
          id='message'
          name='message'
          value={formik.values.message}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />

        <br />
        <br />
        <AcceptButton type='submit' disabled={loading}>
          Send
        </AcceptButton>
      </form>
    </>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <StyledSpinnerContainer>
          <LoadingSpinner size='xl' />
        </StyledSpinnerContainer>
      );
    } else if (success === false) {
      return (
        <FlexRow>
          <Typography color={errorColor} variant='h5'>
            {t('Something went wrong. Please try again later.')}
          </Typography>
        </FlexRow>
      );
    } else if (success === true) {
      return (
        <Success
          addStyles={width < 700 ? 'flex-direction: column;' : undefined}
          message={t('Your message has been sent!')}
        />
      );
    }

    return renderForm();
  };

  return (
    <StyledPageSectionContainer backgroundColor={backgroundColor2}>
      <StyledPageSectionContent>{renderContent()}</StyledPageSectionContent>
    </StyledPageSectionContainer>
  );
};

export default ContactUsPage;
