import {
  TargetLanguage,
  TranslationPayload,
  TranslationPayment,
  TranslationQuote,
  UpdateTranslationQuoteBillingAddressPayload,
} from '../models/TranslationModels';

export enum TranslationActionTypes {
  FETCH_TARGET_LANGUAGES_REQUEST = 'FETCH_TARGET_LANGUAGES_REQUEST',
  FETCH_TARGET_LANGUAGES_SUCCESS = 'FETCH_TARGET_LANGUAGES_SUCCESS',

  CREATE_TRANSLATION_REQUEST = 'CREATE_TRANSLATION_REQUEST',
  CREATE_TRANSLATION_SUCCESS = 'CREATE_TRANSLATION_SUCCESS',

  CREATE_DELIVERY_EMAIL_REQUEST = 'CREATE_DELIVERY_EMAIL_REQUEST',
  CREATE_DELIVERY_EMAIL_SUCCESS = 'CREATE_DELIVERY_EMAIL_SUCCESS',

  GET_DELIVERY_EMAIL_REQUEST = 'GET_DELIVERY_EMAIL_REQUEST',
  GET_DELIVERY_EMAIL_SUCCESS = 'GET_DELIVERY_EMAIL_SUCCESS',

  FETCH_TRANSLATIONS_REQUEST = 'FETCH_TRANSLATIONS_REQUEST',
  FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS',

  CREATE_TRANSLATION_QUOTE_REQUEST = 'CREATE_TRANSLATION_QUOTE_REQUEST',
  CREATE_TRANSLATION_QUOTE_SUCCESS = 'CREATE_TRANSLATION_QUOTE_SUCCESS',
  CREATE_TRANSLATION_QUOTE_FAILURE = 'CREATE_TRANSLATION_QUOTE_FAILURE',

  UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST = 'UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST',
  UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST_SUCCESS = 'UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST_SUCCESS ',
  UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST_FAILURE = 'UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST_FAILURE',

  CREATE_TRANSLATION_PAYMENT_REQUEST = 'CREATE_TRANSLATION_PAYMENT_REQUEST ',
  CREATE_TRANSLATION_PAYMENT_SUCCESS = 'CREATE_TRANSLATION_PAYMENT_SUCCESS ',
  CREATE_TRANSLATION_PAYMENT_FAILURE = 'CREATE_TRANSLATION_PAYMENT_FAILURE',

  UPDATE_TRANSLATION_PAYMENT_STATUS = 'UPDATE_TRANSLATION_PAYMENT_STATUS',
  UPDATE_TRANSLATION_PAYMENT_STATUS_SUCCESS = 'UPDATE_TRANSLATION_PAYMENT_STATUS_SUCCESS',
  UPDATE_TRANSLATION_PAYMENT_STATUS_FAILURE = 'UPDATE_TRANSLATION_PAYMENT_STATUS_FAILURE',

  DELETE_TRANSLATION_QUOTE_REQUEST = 'DELETE_TRANSLATION_QUOTE_REQUEST',
  DELETE_TRANSLATION_QUOTE_SUCCESS = 'DELETE_TRANSLATION_QUOTE_SUCCESS',
  DELETE_TRANSLATION_QUOTE_FAILURE = 'DELETE_TRANSLATION_QUOTE_FAILURE ',
  RESET_DELETE_TRANSLATION_QUOTE = 'RESET_DELETE_TRANSLATION_QUOTE',

  DELETE_STRIPE_PAYMENT_REQUEST = 'DELETE_STRIPE_PAYMENT_REQUEST',
  DELETE_STRIPE_PAYMENT_SUCCESS = 'DELETE_STRIPE_PAYMENT_SUCCESS',
  DELETE_STRIPE_PAYMENT_FAILURE = 'DELETE_STRIPE_PAYMENT_FAILURE',

  RESET_TRANSLATION_QUOTE = 'RESET_TRANSLATION_QUOTE',

  RESET_TRANSLATION_SUCCESS = 'RESET_TRANSLATION_SUCCESS',
  RESET_TRANSLATION_LOADING = 'RESET_TRANSLATION_LOADING',

  RESET_TRANSLATION_STATE = 'RESET_TRANSLATION_STATE',
  LOGOUT = 'LOGOUT',
}

export interface TranslationAction {
  type: TranslationActionTypes;
  payload?: any;
}

export const createTranslation = ({
  formData,
  targetLanguage,
  keepDocumentFormatting,
}: TranslationPayload) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_REQUEST,
  payload: { formData, targetLanguage, keepDocumentFormatting },
});

export const createTranslationSuccess = () => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_SUCCESS,
});

export const resetTranslationQuote = () => ({
  type: TranslationActionTypes.RESET_TRANSLATION_QUOTE,
});

export const createTranslationQuoteRequest = (formData: FormData) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_QUOTE_REQUEST,
  payload: { formData },
});

export const createTranslationQuoteSuccess = (quote: TranslationQuote) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_QUOTE_SUCCESS,
  payload: quote,
});

export const createTranslationQuoteFailure = (errorMessage: string) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_QUOTE_FAILURE,
  payload: errorMessage,
});

export const updateTranslationQuoteBillingAddress = (
  payload: UpdateTranslationQuoteBillingAddressPayload,
  setIsEditingBillingAddress: (isEditing: boolean) => void,
  isCountryChanged: boolean
) => ({
  type: TranslationActionTypes.UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST,
  payload: { payload, callback: setIsEditingBillingAddress, isCountryChanged },
});

export const updateTranslationQuoteBillingAddressSuccess = (
  translationQuote: TranslationQuote
) => ({
  type: TranslationActionTypes.UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST_SUCCESS,
  payload: translationQuote,
});

export const createDeliveryEmailRequest = (email: string) => ({
  type: TranslationActionTypes.CREATE_DELIVERY_EMAIL_REQUEST,
  payload: email,
});

export const createOrUpdateDeliveryEmailSuccess = () => ({
  type: TranslationActionTypes.CREATE_DELIVERY_EMAIL_SUCCESS,
});

export const getDeliveryEmailRequest = () => ({
  type: TranslationActionTypes.GET_DELIVERY_EMAIL_REQUEST,
});

export const getDeliveryEmailSuccess = (email: string) => ({
  type: TranslationActionTypes.GET_DELIVERY_EMAIL_SUCCESS,
  payload: email,
});

export const createTranslationPaymentRequest = (quoteId: number) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_REQUEST,
  payload: quoteId,
});

export const updateTranslationPaymentStatus = (
  paymentId: number,
  paymentStatus: string,
  callback: () => void
) => ({
  type: TranslationActionTypes.UPDATE_TRANSLATION_PAYMENT_STATUS,
  payload: { paymentId, paymentStatus, callback },
});

export const updateTranslationPaymentStatusSuccess = () => ({
  type: TranslationActionTypes.UPDATE_TRANSLATION_PAYMENT_STATUS_SUCCESS,
});

export const updateTranslationPaymentStatusFailure = () => ({
  type: TranslationActionTypes.UPDATE_TRANSLATION_PAYMENT_STATUS_FAILURE,
});

export const createTranslationPaymentSuccess = (
  payment: TranslationPayment,
  clientSecret: string
) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_SUCCESS,
  payload: { payment, clientSecret },
});

export const createTranslationPaymentFailure = () => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_FAILURE,
});

export const resetTranslationState = () => ({
  type: TranslationActionTypes.RESET_TRANSLATION_STATE,
});

export const fetchTargetLanguages = () => ({
  type: TranslationActionTypes.FETCH_TARGET_LANGUAGES_REQUEST,
});

export const fetchTargetLanguagesSuccess = (languages: TargetLanguage[]) => ({
  type: TranslationActionTypes.FETCH_TARGET_LANGUAGES_SUCCESS,
  payload: languages,
});

export const fetchTranslationsRequest = () => ({
  type: TranslationActionTypes.FETCH_TRANSLATIONS_REQUEST,
});

export const fetchTranslationsSuccess = (translations: any[]) => ({
  type: TranslationActionTypes.FETCH_TRANSLATIONS_SUCCESS,
  payload: translations,
});

export const deleteStripePaymentRequest = (paymentId: number) => ({
  type: TranslationActionTypes.DELETE_STRIPE_PAYMENT_REQUEST,
  payload: { paymentId },
});

export const deleteTranslationQuoteRequest = (quoteId: number) => ({
  type: TranslationActionTypes.DELETE_TRANSLATION_QUOTE_REQUEST,
  payload: quoteId,
});

export const deleteTranslationQuoteSuccess = () => ({
  type: TranslationActionTypes.DELETE_TRANSLATION_QUOTE_SUCCESS,
});

export const deleteTranslationQuoteFailure = () => ({
  type: TranslationActionTypes.DELETE_TRANSLATION_QUOTE_FAILURE,
});

export const resetDeleteTranslationQuote = () => ({
  type: TranslationActionTypes.RESET_DELETE_TRANSLATION_QUOTE,
});

export const deleteStripePaymentSuccess = () => ({
  type: TranslationActionTypes.DELETE_STRIPE_PAYMENT_SUCCESS,
});

export const deleteStripePaymentFailure = () => ({
  type: TranslationActionTypes.DELETE_STRIPE_PAYMENT_FAILURE,
});
