import { Country } from '../models/MiscModels';

export enum MiscActionTypes {
  FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST',
  FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS',
  FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE',
}

export interface MiscAction {
  type: MiscActionTypes;
  payload?: any;
}

export const fetchCountriesRequest = () => ({
  type: MiscActionTypes.FETCH_COUNTRIES_REQUEST,
});

export const fetchCountriesSuccess = (countries: Country[]) => ({
  type: MiscActionTypes.FETCH_COUNTRIES_SUCCESS,
  payload: countries,
});

export const fetchCountriesFailure = () => ({
  type: MiscActionTypes.FETCH_COUNTRIES_FAILURE,
});
