import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { LoadingSpinner, Success } from '../../components';
import { FutureProduct, WaitlistUser } from '../../store/models/WaitlistModels';
import {
  AcceptButton,
  FlexColumn,
  primaryColor1,
  primaryColor2,
  textColorSecondary,
} from '../../styles/constants';
import { RootState } from '../../store';
import useWindowSize from '../../hooks/useScreenSize';

const StyledContainer = styled.div`
  min-height: 8rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1rem;

  // border: 1px solid blue;
`;

interface StyledGridProps {
  numberOfWaitlists: number;
}

const StyledGrid = styled.div<StyledGridProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

interface WaitlistsProps {
  waitlistUsers: WaitlistUser[];
  futureProducts: FutureProduct[];
  fetchFutureProducts: {
    loading: boolean;
    success: boolean | undefined;
  };
  fetchWaitlistUsers: {
    loading: boolean;
    success: boolean | undefined;
  };
}

interface FutureProductCardProps {
  futureProduct: FutureProduct;
  waitlistUsers: WaitlistUser[];
  t: any;
}

const FutureProductCard = ({
  futureProduct,
  waitlistUsers,
  t,
}: FutureProductCardProps) => {
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.user?.user?.id);
  const isUserOnWaitlist = waitlistUsers
    .map((wl) => wl.productId)
    .includes(futureProduct.id);

  return (
    <FlexColumn
      addStyles='align-items: flex-start; margin-left: 1rem; margin-bottom: 1rem;'
      id='flex-col'
    >
      <Typography variant='h6' color={primaryColor1}>
        {t(futureProduct.headline)}
      </Typography>
      <Typography variant='body1' color={textColorSecondary}>
        {t(futureProduct.description)}
      </Typography>
      <br />
      {!isUserOnWaitlist ? (
        <div>
          <AcceptButton
            onClick={() => {
              if (userId) {
                navigate(
                  `/join-waitlist/product/${futureProduct.id}/${futureProduct.label}`
                );
              }
            }}
          >
            {' '}
            {t('JOIN OUR WAITLIST')}
          </AcceptButton>
        </div>
      ) : (
        <Success
          message={t('Thanks for joining our waitlist!')}
          variant='body1'
          color={primaryColor2}
        ></Success>
      )}
    </FlexColumn>
  );
};

const Waitlists = ({
  waitlistUsers,
  futureProducts,
  fetchFutureProducts,
  fetchWaitlistUsers,
}: WaitlistsProps) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const renderContent = () => {
    if (fetchFutureProducts.loading || fetchWaitlistUsers.loading) {
      return <LoadingSpinner size='xl' />;
    }

    if (
      fetchFutureProducts.success === false ||
      fetchWaitlistUsers.success === false
    ) {
      return (
        <Typography variant='body1'>
          Oops! There was an error fetching the waitlists for our future
          products, please email us if you want to be kept up to date!
        </Typography>
      );
    }

    return futureProducts.length > 0 ? (
      <StyledGrid numberOfWaitlists={futureProducts.length}>
        {futureProducts.map((futureProduct, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: `${windowSize.width <= 1200 ? 'column' : 'row'}`,
              justifyContent: 'space-between',
            }}
            key={futureProduct.id}
          >
            <FutureProductCard
              futureProduct={futureProduct}
              waitlistUsers={waitlistUsers}
              t={t}
            />

            {index !== futureProducts.length - 1 && (
              <Divider
                orientation={
                  windowSize.width <= 1200 ? 'horizontal' : 'vertical'
                }
              />
            )}
          </div>
        ))}
      </StyledGrid>
    ) : (
      <Typography variant='body1' color={textColorSecondary}>
        {t(
          'Theres no more future products to sign up for at the moment. Please check back later.'
        )}
      </Typography>
    );
  };

  return <StyledContainer>{renderContent()}</StyledContainer>;
};

export default Waitlists;
