import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';

import { RootState } from '../../store';
import {
  createTranslationQuoteRequest,
  fetchTargetLanguages,
  getDeliveryEmailRequest,
} from '../../store/actions/translationActions';
import ChooseTargetLanguage from './ChooseTargetLanguage';
import ChooseDocument from './ChooseDocument';
import ReviewQuote from './ReviewQuote';
import { MobileStepper } from '../../components';
import PaymentInfo from './PaymentInfo';

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface CreateTranslationPageProps {
  navbarHeight: number;
}

export interface Step {
  title: string;
  description: string;
  isShowDescription: boolean;
  component: React.ReactNode;
  isContentCenteredVertically: boolean;
  isContentCenteredHorizontally: boolean;
  goBackToQuoteStep?: () => void;
}

export interface Tier {
  id: number;
  title: 'None' | 'Standard' | 'Advanced';
  description: string;
  value: string;
  pricePerPage: number;
}

const CreateTranslationPage = ({
  navbarHeight,
}: CreateTranslationPageProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // TODO delete pricing tiers from redux
  const { deliveryEmail } = useSelector(
    (state: RootState) => state.translations
  );

  const { id: userId } = useSelector((state: RootState) => state.user.user!);

  const targetLanguages = useSelector(
    (state: RootState) => state.translations.targetLanguages
  );

  const translationPaymentId = useSelector(
    (state: RootState) => state.translations.translationPayment?.id
  );

  const [file, setFile] = useState<File | null>(null);
  const [targetLanguageValue, setTargetLanguageValue] = useState<
    undefined | string
  >(undefined);

  const [promoCode, setPromoCode] = useState<string>('');

  useEffect(() => {
    if (!deliveryEmail) {
      dispatch(getDeliveryEmailRequest());
    }
    dispatch(fetchTargetLanguages());
  }, [deliveryEmail, dispatch]);

  useEffect(() => {
    if (!file) return;

    const targetLanguageId = targetLanguages.find(
      (lang) => lang.value === targetLanguageValue
    )?.id;
    if (!targetLanguageId) return;

    const formData = new FormData();
    formData.append('user_id', userId!.toString());
    formData.append('target_language_id', targetLanguageId.toString());
    formData.append('file', file);
    if (promoCode) {
      formData.append('promo_code', promoCode);
    }

    dispatch(createTranslationQuoteRequest(formData));
  }, [dispatch, file, targetLanguageValue, targetLanguages, promoCode, userId]);

  const [steps, setSteps] = useState<Step[]>([
    {
      title: t('Step 1: Choose Target Language'),
      description: t(
        'Choose which language you want your document translated to'
      ),
      isShowDescription: true,
      isContentCenteredVertically: true,
      isContentCenteredHorizontally: true,
      component: (
        <ChooseTargetLanguage
          value={targetLanguageValue}
          setValue={setTargetLanguageValue}
          completeStep={() => {
            setStepsIsComplete((prevState) => [true, ...prevState.slice(1)]);
          }}
        />
      ),
    },
  ]);

  // Impportant this is the same length as steps belows
  const [stepsIsComplete, setStepsIsComplete] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const translationSteps: Step[] = [
      {
        title: t('Step 1: Choose Target Language'),
        description: t(
          'Choose which language you want your document translated to'
        ),
        isContentCenteredVertically: true,
        isContentCenteredHorizontally: true,
        isShowDescription: true,
        component: (
          <ChooseTargetLanguage
            value={targetLanguageValue}
            setValue={setTargetLanguageValue}
            completeStep={() => {
              setStepsIsComplete((prevState) => [true, ...prevState.slice(1)]);
            }}
          />
        ),
      },
      {
        title: t('Step 2: Choose Document'),
        description: t('.pdf files are supported'),
        isShowDescription: false,
        isContentCenteredVertically: true,
        isContentCenteredHorizontally: true,
        component: (
          <ChooseDocument
            file={file}
            setFile={setFile}
            setActiveStep={setActiveStep}
            completeStep={() => {
              setStepsIsComplete((prevState) =>
                prevState.map((item, index) => (index === 1 ? true : item))
              );
            }}
          />
        ),
      },
      {
        title: t('Step 3: Review Quote'),
        description: t(
          "Based on the length of your document and the pricing tier you chose, we'll let you know how much your translation will cost"
        ),
        isShowDescription: false,
        isContentCenteredVertically: true,
        isContentCenteredHorizontally: true,
        component: (
          <ReviewQuote
            completeStep={() => {
              setStepsIsComplete((prevState) =>
                prevState.map((item, index) => (index === 2 ? true : item))
              );
              setActiveStep((prevState) => prevState + 1);
              if (translationPaymentId !== undefined) {
                searchParams.set('paymentId', translationPaymentId.toString());
              }
              searchParams.set('isPolling', false.valueOf.toString());
            }}
            setActiveStep={setActiveStep}
            resetState={() => {
              setStepsIsComplete((prevState) =>
                prevState.map((item, index) => false)
              );
              setFile(null);
              setPromoCode('');
              setTargetLanguageValue(undefined);
            }}
          />
        ),
      },
      {
        title: t('Step 4: Payment'),
        description: t('Enter your credit card information'),
        isShowDescription: false,
        isContentCenteredVertically: true,
        isContentCenteredHorizontally: true,
        component: (
          <PaymentInfo
            goBackToQuoteStep={() => {
              setActiveStep((prevState) => prevState - 1);
            }}
            completeStep={() => navigate('/create-translation/success')}
          />
        ),
      },
    ];

    setSteps(translationSteps);
  }, [
    targetLanguageValue,
    file,
    deliveryEmail,
    dispatch,
    navigate,
    t,
    translationPaymentId,
    searchParams,
    promoCode,
  ]);

  return (
    <StyledPageContainer>
      <MobileStepper
        navbarHeight={navbarHeight}
        steps={steps}
        setSteps={setSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setTargetLanguageValue={setTargetLanguageValue}
        stepsIsComplete={stepsIsComplete}
        setStepsIsComplete={setStepsIsComplete}
      />
    </StyledPageContainer>
  );
};

export default CreateTranslationPage;
