import React, { useState } from 'react';
import {
  Grid,
  Card,
  Typography,
  Divider,
  IconButton,
  Collapse,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { primaryColor1 } from '../styles/constants';

interface ListItemProps {
  item: {
    title: string;
    description: string;
    icon?: React.ReactNode;
  };
}

interface ListItem {
  title: string;
  description: string;
  icon?: React.ReactNode;
}

const ListItemComponent: React.FC<ListItemProps> = ({ item }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <Grid item xs={12}>
      <Card
        sx={{
          backgroundColor: 'inherit',
          boxShadow: 'none',
          border: 'none',
        }}
      >
        {item.icon}
        <Typography variant='h6'>{t(item.title)}</Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div />
          <IconButton
            onClick={toggleOpen}
            aria-expanded={isOpen}
            aria-label='show description'
          >
            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        <Collapse in={isOpen} timeout='auto' unmountOnExit>
          <Typography variant='body1'>{t(item.description)}</Typography>
        </Collapse>
      </Card>
    </Grid>
  );
};

interface ExpandingContentListProps {
  header: string;
  listItems: ListItem[];
  alignHeader?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  sx?: any;
}

const ExpandingContentList: React.FC<ExpandingContentListProps> = ({
  listItems,
  header,
  alignHeader,
  sx,
}) => {
  return (
    <>
      <Typography
        align={alignHeader ? alignHeader : undefined}
        variant='h4'
        color={`${primaryColor1}`}
        gutterBottom
        sx={sx}
      >
        {header}
      </Typography>
      <br />
      <Grid container spacing={2}>
        {listItems.map((item, index) => (
          <React.Fragment key={item.title}>
            <ListItemComponent item={item} />
            {index < listItems.length - 1 && (
              <Divider orientation='horizontal' />
            )}
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
};

export default ExpandingContentList;
