import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ChairIcon from '@mui/icons-material/Chair';

import {
  FlexColumn,
  FlexRow,
  primaryColor1,
  primaryColor2,
} from '../../styles/constants';

const StyleBenefitsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;
  justify-items: center;
  max-width: 100%;
  color: ${primaryColor2};

  @media (min-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 750px) {
    grid-gap: 3rem;
  }

  @media (min-width: 1230px) {
    grid-gap: 4rem;
  }
`;

interface BenefitProps {
  icon: any;
  title: string;
  description: string;
  t?: any;
  addSpace: boolean;
}

const Benefit = ({ icon, title, description, t, addSpace }: BenefitProps) => {
  return (
    <FlexColumn>
      <FlexRow>
        {icon}
        {title !== 'Cheap' ? '\u00A0 \u00A0' : ''}
        <Typography
          variant='h6'
          fontWeight='bold'
          sx={{ color: primaryColor1 }}
        >
          {t(title)}
        </Typography>
      </FlexRow>
      <br />
      <Typography variant='body1' sx={{ color: primaryColor1 }}>
        {t(description)}
      </Typography>
    </FlexColumn>
  );
};

const benefitsData = [
  {
    icon: <ElectricBoltIcon fontSize='large' />,
    title: 'Fast',
    description:
      'Humans are slow and often busy, our AI is fast and always available',
    addSpace: true,
  },
  {
    icon: <AttachMoneyIcon fontSize='large' />,
    title: 'Cheap',
    description: 'Get top quality for 1/10th the price',
    addSpace: false,
  },
  {
    icon: <ChairIcon fontSize='large' />,
    title: 'Easy',
    description:
      "1 click sign in, upload your document and relax. We'll email you when it's ready",
    addSpace: true,
  },
];

const Benefits = () => {
  const { t } = useTranslation();

  return (
    <div>
      <FlexRow>
        <Typography
          sx={{ fontWeight: 'bold' }}
          variant='h4'
          color={`${primaryColor1}`}
          gutterBottom
        >
          {t('Fast, Cheap & Easy')}
        </Typography>
      </FlexRow>
      <br />
      <br />
      <br />
      <br />
      <StyleBenefitsContainer>
        {benefitsData.map((benefit, index) => (
          <Benefit key={index} {...benefit} t={t} />
        ))}
      </StyleBenefitsContainer>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Benefits;
