import api from '../../utils/api';
import { UserLoginWithGooglePayload } from '../models/UserModels';

export const loginWithGoogle = (payload: UserLoginWithGooglePayload) => {
  return api.post('/auth/google', payload);
};

export const acceptPrivacyPolicy = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.post('/privacy/accept');
};

// Assuming a similar structure for accepting Terms of Service
export const acceptTermsOfService = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.post('/terms/accept');
};

export const createUniqueVisitor = (fbclid: string | null) => {
  return api.post('/visitor', { fbclid });
};

export const getAuthTokenCheck = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.get('/auth/check');
};

export const contactUs = (token: string, message: string, email: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.post('/contact', { message, email });
};

export const updateUserLanguage = (token: string, language: 'en' | 'fr') => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.patch('/user/language', { language });
};

export const fetchUser = (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.get('/user');
};

export const registerEnterpriseUser = (
  token: string,
  payload: {
    email: string;
    password: string;
    activationCode: string;
    organizationId: number;
  }
) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.post('/enterprise/users/register', payload);
};

export const loginEnterpriseUser = (payload: {
  email: string;
  password: string;
}) => {
  return api.post('/enterprise/users/login', payload);
};
