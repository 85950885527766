import { User } from '../store/models/UserModels';

export const TOKEN_KEY_USER = 'login_token';
export const KEY_USER = 'user_key';
export const TOKEN_AGE_KEY = 'token_age';

export const setToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY_USER, token);

  const currentTime = new Date().getTime();
  localStorage.setItem(TOKEN_AGE_KEY, currentTime.toString());
};

export const getToken = () => localStorage.getItem(TOKEN_KEY_USER);

export const getTokenAge = () => {
  const tokenAge = localStorage.getItem(TOKEN_AGE_KEY);

  if (!tokenAge) {
    return 1000000;
  }

  const currentTime = new Date().getTime();
  const ageInMilliseconds = currentTime - Number(tokenAge);
  const ageInMinutes = ageInMilliseconds / (1000 * 60);

  return ageInMinutes;
};

export const removeToken = () => localStorage.removeItem(TOKEN_KEY_USER);

export const setUser = (user: User) => {
  localStorage.setItem(KEY_USER, JSON.stringify(user));
};

export const getUser = (): User | null => {
  const user = localStorage.getItem(KEY_USER);
  try {
    return JSON.parse(user as string);
  } catch (err) {
    return null;
  }
};

export const removeUser = () => localStorage.removeItem(KEY_USER);
