import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Card,
  Typography,
  List,
  ListItem,
  Box,
  useMediaQuery,
  Divider,
} from '@mui/material';
import { FlexRow, primaryColor1, primaryColor2 } from '../../styles/constants';

interface Tier {
  emoji: string;
  title: string;
  description: string;
  price: string;
  perfectFor: string[];
  icon?: React.ReactNode;
}

interface PricingProps {
  tiers: Tier[];
}

const Pricing: React.FC<PricingProps> = ({ tiers }) => {
  const { t } = useTranslation();
  const isHorizontalLayout = useMediaQuery(`(max-width: 900px)`);

  return (
    <>
      <FlexRow>
        <Typography
          sx={{ fontWeight: 'bold' }}
          variant='h4'
          color={`${primaryColor1}`}
          gutterBottom
        >
          {t('Pricing')}
        </Typography>
      </FlexRow>
      <br />
      <br />
      <br />
      <Grid
        container
        spacing={2}
        direction={isHorizontalLayout ? 'column' : 'row'}
        justifyContent='space-around'
      >
        {tiers.map((tier, index) => (
          <Grid item xs={12} md={4} key={tier.title}>
            <Card
              sx={{
                backgroundColor: 'inherit',
                boxShadow: 'none',
                border: 'none',
                padding: 2,
                display: 'grid',
                gridTemplateRows: 'auto auto auto 1fr',
                gap: 1,
              }}
            >
              <FlexRow>
                {tier.icon}
                {'\u00A0 \u00A0'}
                <Typography
                  variant='h6'
                  fontWeight='bold'
                  sx={{ gridRow: 1, mb: 1, color: primaryColor1 }}
                >
                  {t(tier.title)}
                </Typography>
              </FlexRow>
              <FlexRow>
                <Typography
                  variant='body1'
                  fontWeight='bold'
                  sx={{ gridRow: 2, mb: 1 }}
                >
                  {t(tier.description)}
                </Typography>
              </FlexRow>
              <FlexRow>
                <Typography
                  variant='body2'
                  fontWeight='bold'
                  sx={{ gridRow: 3, mb: 1, color: primaryColor2 }}
                >
                  {t(tier.price)}
                </Typography>
              </FlexRow>
              <Box
                sx={{
                  gridRow: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <List sx={{ listStyleType: 'disc', ml: 2 }}>
                  {tier.perfectFor.map((item, idx) => (
                    <ListItem key={idx} sx={{ display: 'list-item' }}>
                      {t(item)} {idx === 2 && tier.emoji}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Card>
            {index !== tiers.length - 1 && isHorizontalLayout && (
              <Divider orientation='horizontal' />
            )}
          </Grid>
        ))}
      </Grid>
      <br />
      <br />
    </>
  );
};

export default Pricing;
