import { put, select, takeLatest } from 'redux-saga/effects';
import {
  loginWithGoogleSuccess,
  AuthAction,
  loginWithGoogleFailure,
  acceptPrivacyPolicySuccess,
  acceptPrivacyPolicyFailure,
  acceptTermsOfServiceSuccess,
  acceptTermsOfServiceFailure,
  fetchUserSuccess,
} from '../actions/userActions';
import { AuthActionTypes } from '../actions/userActions';
import {
  acceptPrivacyPolicy,
  acceptTermsOfService,
  contactUs,
  getAuthTokenCheck,
  loginWithGoogle,
  updateUserLanguage,
  fetchUser,
  registerEnterpriseUser,
  loginEnterpriseUser,
} from '../api/userApi';
import { callGenerator } from './sagaUtils';
import { RootState } from '..';

function* fetchUserSaga() {
  // Used as a refresh
  const currentToken: string = yield select(
    (state: RootState) => state.user.token
  );

  try {
    const {
      data: { user, token },
    } = yield* callGenerator(fetchUser, currentToken);
    yield put(fetchUserSuccess({ user, token }));
  } catch (error: any) {
    yield put({ type: AuthActionTypes.FETCH_USER_FAILURE });
  }
}

function* loginUserWithGoogleSaga(action: AuthAction) {
  try {
    const {
      data: { user, token },
    } = yield* callGenerator(loginWithGoogle, action.payload);
    yield put(loginWithGoogleSuccess({ user, token }));
  } catch (error: any) {
    yield put(loginWithGoogleFailure(error.message));
    console.log('ERROR', error);
  } finally {
    yield put({ type: AuthActionTypes.RESET_LOGIN_LOADING });
  }
}

function* checkAuthTokenSaga(action: AuthAction) {
  try {
    yield* callGenerator(getAuthTokenCheck, action.payload.token);
    // If it's still good do nothing
  } catch (error: any) {
    // If it's not good, log out
    yield put({ type: AuthActionTypes.LOGOUT });
  }
}

function* acceptPrivacyPolicySaga(action: AuthAction) {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    const {
      data: { user },
    } = yield* callGenerator(acceptPrivacyPolicy, token);
    yield put(acceptPrivacyPolicySuccess(user));
  } catch (error: any) {
    yield put(acceptPrivacyPolicyFailure(error.message));
    console.log('ERROR', error);
  } finally {
    yield put({ type: AuthActionTypes.RESET_LOGIN_LOADING });
  }
}

function* accepTermsOfServiceSaga(action: AuthAction) {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    const {
      data: { user },
    } = yield* callGenerator(acceptTermsOfService, token);
    yield put(acceptTermsOfServiceSuccess(user));
  } catch (error: any) {
    yield put(acceptTermsOfServiceFailure(error.message));
    console.log('ERROR', error);
  } finally {
    yield put({ type: AuthActionTypes.RESET_LOGIN_LOADING });
  }
}

function* contactUsSaga(action: AuthAction) {
  const token: string = yield select((state: RootState) => state.user.token);
  const { message, email } = action.payload;

  try {
    yield* callGenerator(contactUs, token, message, email);
    yield put({ type: AuthActionTypes.CONTACT_US_SUCCESS });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.CONTACT_US_FAILURE });
  }
}

function* updateUserLanguageSaga(action: AuthAction) {
  const token: string = yield select((state: RootState) => state.user.token);
  const language = action.payload;

  try {
    const { data } = yield* callGenerator(updateUserLanguage, token, language);
    yield put({
      type: AuthActionTypes.UPDATE_USER_LANGUAGE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.UPDATE_USER_LANGUAGE_FAILURE });
  }
}

function* registerEnterpriseUserSaga(action: AuthAction) {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    const { data } = yield* callGenerator(
      registerEnterpriseUser,
      token,
      action.payload
    );
    yield put({
      type: AuthActionTypes.ENTERPRISE_REGISTER_USER_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.ENTERPRISE_REGISTER_USER_FAILURE });
  } finally {
    yield put({ type: AuthActionTypes.RESET_REGISTER_LOADING });
  }
}

function* loginEnterpriseUserSaga(action: AuthAction) {
  try {
    const { data } = yield* callGenerator(loginEnterpriseUser, action.payload);
    yield put({
      type: AuthActionTypes.ENTERPRISE_LOGIN_USER_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.ENTERPRISE_LOGIN_USER_FAILURE });
  } finally {
    yield put({ type: AuthActionTypes.RESET_LOGIN_LOADING });
  }
}

export default function* rootSaga() {
  yield takeLatest(AuthActionTypes.FETCH_USER_REQUEST, fetchUserSaga);

  yield takeLatest(
    AuthActionTypes.LOGIN_WITH_GOOGLE_REQUEST,
    loginUserWithGoogleSaga
  );

  yield takeLatest(
    AuthActionTypes.ACCEPT_PRIVACY_POLICY_REQUEST,
    acceptPrivacyPolicySaga
  );

  yield takeLatest(
    AuthActionTypes.ACCEPT_TERMS_OF_SERVICE_REQUEST,
    accepTermsOfServiceSaga
  );

  yield takeLatest(AuthActionTypes.CONTACT_US_REQUEST, contactUsSaga);

  yield takeLatest(
    AuthActionTypes.UPDATE_USER_LANGUAGE_REQUEST,
    updateUserLanguageSaga
  );

  yield takeLatest(AuthActionTypes.CHECK_AUTH_TOKEN, checkAuthTokenSaga);

  yield takeLatest(
    AuthActionTypes.ENTERPRISE_REGISTER_USER_REQUEST,
    registerEnterpriseUserSaga
  );

  yield takeLatest(
    AuthActionTypes.ENTERPRISE_LOGIN_USER_REQUEST,
    loginEnterpriseUserSaga
  );
}
