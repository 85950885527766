import { put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchFutureProductsSuccess,
  fetchFutureProductsFailure,
  fetchWaitlistUsersSuccess,
  fetchWaitlistUsersFailure,
  addWaitlistUserSuccess,
  addWaitlistUserFailure,
  WaitlistActionTypes,
  WaitlistAction,
} from '../actions/waitlistActions';
import {
  getFutureProducts,
  getWaitlistUsers,
  createWaitlistUser,
} from '../api/waitlistApi';
import { callGenerator } from './sagaUtils';
import { RootState } from '..';
import { ERROR_CREDENTIALS_OR_TOKEN_EXPIRED } from './constants';

function* fetchFutureProductsSaga() {
  try {
    const response = yield* callGenerator(getFutureProducts);
    yield put(fetchFutureProductsSuccess(response.data.futureProducts));
  } catch (error) {
    yield put(fetchFutureProductsFailure());
    console.error('Fetch Future Products Error:', error);
  }
}

function* fetchWaitlistUsersSaga(action: WaitlistAction) {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    const response = yield* callGenerator(getWaitlistUsers, token);
    yield put(fetchWaitlistUsersSuccess(response.data.waitlistUsers));
  } catch (error: any) {
    if (error.response.data) {
      const { detail } = error.response.data;

      if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
        yield put({ type: 'LOGOUT' });
      }
    }
    yield put(fetchWaitlistUsersFailure());
    console.error('Fetch Waitlist Users Error:', error);
  }
}

// Saga to add a user to the waitlist
function* addWaitlistUserSaga(action: WaitlistAction) {
  const token: string = yield select((state: RootState) => state.user.token);

  try {
    const { productId, reason } = action.payload;
    const response = yield* callGenerator(
      createWaitlistUser,
      productId,
      reason,
      token
    );
    yield put(addWaitlistUserSuccess(response.data.waitlistUsers));
  } catch (error: any) {
    yield put(addWaitlistUserFailure());
    if (error.response.data) {
      const { detail } = error.response.data;

      if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
        yield put({ type: 'LOGOUT' });
      }
    }
    console.error('Add Waitlist User Error:', error);
  }
}

function* waitlistSaga() {
  yield takeLatest(
    WaitlistActionTypes.FETCH_FUTURE_PRODUCTS_REQUEST,
    fetchFutureProductsSaga
  );
  yield takeLatest(
    WaitlistActionTypes.FETCH_WAITLIST_USERS_REQUEST,
    fetchWaitlistUsersSaga
  );
  yield takeLatest(
    WaitlistActionTypes.ADD_WAITLIST_USER_REQUEST,
    addWaitlistUserSaga
  );
}

export default waitlistSaga;
