import api from '../../utils/api';
import {
  TranslationPayload,
  UpdateTranslationQuoteBillingAddressPayload,
} from '../models/TranslationModels';

export const fetchTargetLanguages = async (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await api.get('/translations/target-languages');
};

// TODO should both of these be the same route?
// I don't even think the no-formatting route is being used
export const createTranslation = async (
  payload: TranslationPayload,
  token: string
) => {
  const { keepDocumentFormatting, ...rest } = payload;

  if (keepDocumentFormatting) {
    return await api.post(
      `/translate/with-formatting/target-language/${rest.targetLanguage.id}`,
      rest.formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          // Add token bearer to header
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  return await api.post(
    `/translate/no-formatting/target-language/${rest.targetLanguage.id}`,
    rest.formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        // Add token bearer to header
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const fetchTranslations = async (token: string, userId: number) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await api.get(`/translations?userId=${userId}`);
};

export const createTranslationQuote = async (
  formData: FormData,
  token: string
) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await api.post('/translations/quote', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const createTranslationPayment = async (
  quoteId: number,
  token: string
) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await api.post('/translations/payment', { quoteId });
};

export const updateTranslationPayment = async (
  paymentId: number,
  paymentStatus: string,
  token: string
) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await api.put(
    `/translations/payment/${paymentId}/status/${paymentStatus}`
  );
};

export const createOrUpdateDeliveryEmail = async (
  token: string,
  email: string
) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await api.post('/translations/delivery-email', { email });
};

export const getDeliveryEmail = async (token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await api.get('/translations/delivery-email');
};

export const deleteStripePayment = async (paymentId: string, token: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await api.delete(`/translations/stripe-payment`, {
    data: { paymentId },
  });
};

export const deleteTranslationQuote = async (
  quoteId: number,
  token: string
) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return await api.delete(`/translations/quote/${quoteId}`);
};

export const updateTranslationQuoteBillingAddress = (
  payload: UpdateTranslationQuoteBillingAddressPayload,
  token: string
) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.put('/translations/quote/billing-address', payload);
};
