import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FlexRow, AcceptButton, primaryColor1 } from '../../styles/constants';
import { Typography } from '@mui/material';
import { registerEnterpriseUserRequest } from 'src/store/actions/userActions';
import { LoadingSpinner } from 'src/components';
import { useState } from 'react';

const validationSchema = yup.object({
  activationCode: yup
    .string()
    .min(6, 'Activation code should be of minimum 6 characters length')
    .required('Activation code is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match')
    .required('Confirm Password is required'),
});

const StyledTextField = styled(TextField)`
  margin: 0.5rem 0;
`;

const EnterpriseRegisterForm = ({
  email,
  organizationId,
}: {
  email: string;
  organizationId: number;
}) => {
  const dispatch = useDispatch();
  const { success, loading: userRegisterLoading } = useSelector(
    (state: any) => state.user.register
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  const renderResult = () => {
    if (!isSubmitted) {
      return <></>;
    }

    if (userRegisterLoading) {
      return (
        <FlexRow>
          <LoadingSpinner />
        </FlexRow>
      );
    }

    if (success) {
      return (
        <div>
          <FlexRow>
            <Typography variant='h6' sx={{ color: primaryColor1 }}>
              Account created successfully
            </Typography>
          </FlexRow>
          <FlexRow>
            <Typography variant='h6' sx={{ color: primaryColor1 }}>
              Now you can &nbsp;
              <Link to='/enterprise/login'>Login</Link>
            </Typography>
          </FlexRow>
        </div>
      );
    } else {
      return (
        <FlexRow>
          <Typography variant='h6' sx={{ color: primaryColor1 }}>
            Account creation failed. Please try again.
          </Typography>
        </FlexRow>
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      email: email, // Initialize with the passed-in email
      activationCode: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: ({ email, password, activationCode }) => {
      console.log('submitting', email, password);
      setIsSubmitted(true);
      dispatch(
        registerEnterpriseUserRequest({
          email,
          activationCode,
          password,
          organizationId,
        })
      );
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FlexRow>
          <Typography
            variant='h4'
            sx={{ color: primaryColor1, marginBottom: '2rem' }}
          >
            Create your Enterprise Account
          </Typography>
        </FlexRow>
        <StyledTextField
          fullWidth
          id='email'
          name='email'
          label='Email'
          value={formik.values.email}
          InputProps={{ readOnly: true }} // Make the email field read-only
          InputLabelProps={{ shrink: true }}
        />
        <StyledTextField
          fullWidth
          id='activationCode'
          name='activationCode'
          label='Activation Code'
          value={formik.values.activationCode}
          onChange={formik.handleChange}
          error={
            formik.touched.activationCode &&
            Boolean(formik.errors.activationCode)
          }
          helperText={
            formik.touched.activationCode && formik.errors.activationCode
          }
          InputLabelProps={{ shrink: true }}
        />
        <StyledTextField
          fullWidth
          id='password'
          name='password'
          label='Choose Password'
          type='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputLabelProps={{ shrink: true }}
          autoComplete='new-password'
        />
        <StyledTextField
          fullWidth
          id='confirmPassword'
          name='confirmPassword'
          label='Confirm Password'
          type='password'
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          InputLabelProps={{ shrink: true }}
          autoComplete='new-password'
        />
        <AcceptButton
          disabled={userRegisterLoading}
          variant='contained'
          fullWidth
          type='submit'
          data-testid='register-form-submit-button'
        >
          Create
        </AcceptButton>
      </form>
      <br />
      <br />
      <br />
      <br />
      <FlexRow>{renderResult()}</FlexRow>
    </div>
  );
};

export default EnterpriseRegisterForm;
