import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import { LoadingSpinner } from '../../components';
import { useEffect } from 'react';
import { resetDeleteTranslationQuote } from '../../store/actions/translationActions';

interface ChooseTargetLanguageProps {
  value: string | undefined;
  setValue: (value: string) => void;
  completeStep: () => void;
}

const ChooseTargetLanguage = ({
  value,
  setValue,
  completeStep,
}: ChooseTargetLanguageProps) => {
  const dispatch = useDispatch();
  const { loading, targetLanguages } = useSelector(
    (state: RootState) => state.translations
  );

  useEffect(() => {
    dispatch(resetDeleteTranslationQuote());
  }, [dispatch]);

  return loading ? (
    <LoadingSpinner size='l' />
  ) : (
    <RadioButtonGroup
      options={targetLanguages}
      value={value}
      setValue={setValue}
      completeStep={completeStep}
    />
  );
};

export default ChooseTargetLanguage;
