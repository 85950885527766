import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from '@emotion/styled';

import { primaryColor2, primaryColor2Darkened } from '../../styles/constants';

interface FileUploadProps {
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  completeStep: () => void;
}

const Input = styled('input')({
  display: 'none',
});

const UploadButton = styled(Button)({
  backgroundColor: primaryColor2,
  color: 'white',
  '&:hover': {
    backgroundColor: primaryColor2Darkened,
  },
});

const FileUpload = ({ setFile, completeStep }: FileUploadProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
      completeStep();
    }
  };

  const handleButtonClick = () => {
    inputRef.current?.click(); // Trigger the file input dialog
  };

  return (
    <div>
      <Input
        ref={inputRef}
        // accept='.pdf, .docx' // Adjust accepted file types as needed
        accept='.pdf' // Adjust accepted file types as needed
        id='contained-button-file'
        multiple
        type='file'
        onChange={handleFileChange}
      />
      <UploadButton
        variant='contained'
        startIcon={<CloudUploadIcon />}
        onClick={handleButtonClick}
      >
        {t('CHOOSE DOCUMENT')}
      </UploadButton>
    </div>
  );
};

export default FileUpload;
