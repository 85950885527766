import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import userReducer from './authReducer';
import translationsReducer from './translationsReducer';
import waitlistReducer from './waitlistReducer';
import miscReducer from './miscReducer';

export default combineReducers({
  router: routerReducer,
  user: userReducer,
  translations: translationsReducer,
  waitlists: waitlistReducer,
  misc: miscReducer,
});
