import { MiscAction, MiscActionTypes } from '../actions/miscActions';
import { Country } from '../models/MiscModels';

interface MiscState {
  countries: Country[];

  fetchCountries: {
    loading: boolean;
    success: boolean | undefined;
  };
}

const initialState = {
  countries: [],

  fetchCountries: {
    loading: false,
    success: undefined,
  },
};

function miscReducer(state = initialState, action: MiscAction): MiscState {
  switch (action.type) {
    case MiscActionTypes.FETCH_COUNTRIES_REQUEST:
      return {
        ...state,
        fetchCountries: { ...state.fetchCountries, loading: true },
      };
    case MiscActionTypes.FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
        fetchCountries: { loading: false, success: true },
      };
    case MiscActionTypes.FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        fetchCountries: { loading: false, success: false },
      };
    default:
      return state;
  }
}

export default miscReducer;
