import {
  TranslationAction,
  TranslationActionTypes,
} from '../actions/translationActions';
import {
  TargetLanguage,
  Translation,
  TranslationPayment,
  TranslationQuote,
} from '../models/TranslationModels';

interface TranslationState {
  targetLanguages: TargetLanguage[];
  translations: Translation[];
  translationQuote: TranslationQuote | undefined;
  translationPayment: TranslationPayment | undefined;
  // TODO why is this here and not in authReducer?
  enterpriseLogin: {
    loading: boolean;
    success: boolean | undefined;
  };
  createTranslationPayment: {
    loading: boolean;
    success: boolean | undefined;
  };
  deleteTranslationPayment: {
    loading: boolean;
    success: boolean | undefined;
  };
  createTranslationQuote: {
    loading: boolean;
    success: boolean | undefined;
    errorMessage: string | undefined;
  };
  deleteTranslationQuote: {
    loading: boolean;
    success: boolean | undefined;
  };
  updateTranslationQuoteBillingAddress: {
    loading: boolean;
    success: boolean | undefined;
  };
  clientSecret: string | undefined;
  deliveryEmail: string | null;
  loading: boolean;
  success: boolean | undefined;
}

const initialState: TranslationState = {
  targetLanguages: [],
  translations: [],
  translationQuote: undefined,
  translationPayment: undefined,
  enterpriseLogin: {
    loading: false,
    success: undefined,
  },
  createTranslationPayment: {
    loading: false,
    success: undefined,
  },
  deleteTranslationPayment: {
    loading: false,
    success: undefined,
  },
  createTranslationQuote: {
    loading: false,
    success: undefined,
    errorMessage: undefined,
  },
  deleteTranslationQuote: {
    loading: false,
    success: undefined,
  },
  updateTranslationQuoteBillingAddress: {
    loading: false,
    success: undefined,
  },
  clientSecret: undefined,
  deliveryEmail: null,
  loading: false,
  success: undefined,
};

function translationReducer(
  state = initialState,
  action: TranslationAction
): TranslationState {
  switch (action.type) {
    case TranslationActionTypes.FETCH_TARGET_LANGUAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TranslationActionTypes.FETCH_TARGET_LANGUAGES_SUCCESS:
      return {
        ...state,
        targetLanguages: action.payload,
        loading: false,
        success: true,
      };
    case TranslationActionTypes.CREATE_TRANSLATION_REQUEST:
      return {
        ...state,
        loading: true,
        success: undefined,
      };
    case TranslationActionTypes.CREATE_TRANSLATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case TranslationActionTypes.CREATE_TRANSLATION_QUOTE_REQUEST:
      return {
        ...state,
        createTranslationQuote: {
          loading: true,
          success: undefined,
          errorMessage: undefined,
        },
        translationQuote: undefined,
      };
    case TranslationActionTypes.CREATE_TRANSLATION_QUOTE_SUCCESS:
      return {
        ...state,
        createTranslationQuote: {
          loading: false,
          success: true,
          errorMessage: undefined,
        },
        translationQuote: action.payload,
      };

    case TranslationActionTypes.CREATE_TRANSLATION_QUOTE_FAILURE:
      return {
        ...state,
        createTranslationQuote: {
          loading: false,
          success: false,
          errorMessage: action.payload,
        },
      };

    case TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_REQUEST:
      return {
        ...state,
        createTranslationPayment: {
          ...state.translationPayment,
          loading: true,
          success: undefined,
        },
      };

    case TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_SUCCESS:
      return {
        ...state,
        createTranslationPayment: {
          loading: false,
          success: true,
        },
        translationPayment: action.payload.payment,
        clientSecret: action.payload.clientSecret,
      };

    case TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_FAILURE:
      return {
        ...state,
        createTranslationPayment: {
          loading: false,
          success: false,
        },
      };

    case TranslationActionTypes.DELETE_TRANSLATION_QUOTE_REQUEST:
      return {
        ...state,
        translationQuote: undefined,
        deleteTranslationQuote: {
          loading: true,
          success: undefined,
        },
      };
    case TranslationActionTypes.DELETE_TRANSLATION_QUOTE_SUCCESS:
      return {
        ...state,
        deleteTranslationQuote: {
          loading: false,
          success: true,
        },
      };
    case TranslationActionTypes.DELETE_TRANSLATION_QUOTE_FAILURE:
      return {
        ...state,
        deleteTranslationQuote: {
          loading: false,
          success: false,
        },
      };
    case TranslationActionTypes.RESET_DELETE_TRANSLATION_QUOTE:
      return {
        ...state,
        deleteTranslationQuote: {
          loading: false,
          success: undefined,
        },
      };
    case TranslationActionTypes.DELETE_STRIPE_PAYMENT_REQUEST:
      return {
        ...state,
        deleteTranslationPayment: {
          loading: true,
          success: undefined,
        },
      };
    case TranslationActionTypes.DELETE_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        deleteTranslationPayment: {
          loading: false,
          success: true,
        },
      };
    case TranslationActionTypes.DELETE_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        deleteTranslationPayment: {
          loading: false,
          success: false,
        },
      };

    case TranslationActionTypes.FETCH_TRANSLATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        success: undefined,
      };

    case TranslationActionTypes.FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        translations: action.payload.translations,
        loading: false,
        success: true,
      };

    case TranslationActionTypes.CREATE_DELIVERY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        success: undefined,
      };

    case TranslationActionTypes.CREATE_DELIVERY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case TranslationActionTypes.GET_DELIVERY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true,
        success: undefined,
      };
    case TranslationActionTypes.GET_DELIVERY_EMAIL_SUCCESS:
      return {
        ...state,
        deliveryEmail: action.payload.email,
        loading: false,
        success: true,
      };
    case TranslationActionTypes.RESET_TRANSLATION_SUCCESS:
      return {
        ...state,
        success: undefined,
      };
    case TranslationActionTypes.RESET_TRANSLATION_LOADING:
      return {
        ...state,
        loading: false,
      };

    case TranslationActionTypes.UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST:
      return {
        ...state,
        updateTranslationQuoteBillingAddress: {
          loading: true,
          success: undefined,
        },
      };
    case TranslationActionTypes.UPDATE_TRANSLATION_QUOTE_BILLING_ADDRESS_REQUEST_SUCCESS:
      return {
        ...state,
        updateTranslationQuoteBillingAddress: {
          loading: false,
          success: true,
        },
        translationQuote: action.payload,
      };
    case TranslationActionTypes.RESET_TRANSLATION_STATE:
      return {
        ...initialState,
        targetLanguages: state.targetLanguages,
      };

    case TranslationActionTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
}

export default translationReducer;
