import { useRef, useState, useEffect } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

const useDimensions = (): [React.RefObject<HTMLElement>, Dimensions] => {
  const ref = useRef<HTMLElement>(null);
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setDimensions({
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return [ref, dimensions];
};

export default useDimensions;
