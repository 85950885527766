import axios from 'axios';
import { getToken } from './auth';

const getApiBaseUrl = () => {
  const env = process.env.NODE_ENV;
  let apiUrl;
  if (env === 'production') {
    apiUrl = process.env.REACT_APP_PROD_API_URL;
  } else {
    apiUrl = process.env.REACT_APP_DEV_API_URL;
  }

  return apiUrl;
};

const axiosInstance = axios.create({
  baseURL: getApiBaseUrl(),
});

// Set up an interceptor to attach the token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    // Add token bearer to axios instance
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
