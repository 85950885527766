import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Link, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { AcceptButton } from '../../styles/constants';
import { acceptPrivacyPolicyRequest } from '../../store/actions/userActions';
import { RootState } from '../../store';
import { LoadingSpinner } from '../../components';

const PrivacyPolicyPage: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, success } = useSelector(
    (state: RootState) => state.user.updatePrivacyPolicy
  );

  useEffect(() => {
    if (success === true) navigate('/create-translation');
  }, [success, navigate]);

  return (
    <Container component='main' maxWidth='md' sx={{ pb: 8, mb: 0, mt: 8 }}>
      {loading ? (
        <LoadingSpinner size='xxl' />
      ) : (
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant='h4' gutterBottom>
            Privacy Policy
          </Typography>
          <Typography variant='h6' gutterBottom>
            Effective Date: June 3rd, 2024
          </Typography>
          <Typography paragraph>
            Welcome to Smart Build AI Inc. We are committed to protecting your
            privacy and ensuring that your personal information is handled in a
            safe and responsible manner. This Privacy Policy outlines how we
            collect, use, share, and protect information obtained from our users
            through our website, mobile application, and services.
          </Typography>
          <Typography variant='h5' gutterBottom>
            Information Collection
          </Typography>
          <Typography paragraph>
            We collect information from you when you register on our site, place
            an order, subscribe to our newsletter, or fill out a form. The types
            of personal information we may collect include:
            <br />
            - Name and contact information (email address, mailing address,
            phone number)
            <br />
            - Payment details (credit card information, billing address)
            <br />
            - Preferences and feedback
            <br />
            - Any other information you choose to provide directly
            <br />
            - IP address: When you visit our site, we automatically collect your
            IP address. This helps us determine your location to show
            appropriate pricing based on your region. We do not use this
            information for any other purposes.
            <br />
            Google User Data:
            <br />
            When you log in through Google, we collect your primary email
            address and profile picture from your Google Account. We use this
            information to uniquely identify you in our application and to
            personalize your experience by displaying your Google profile
            picture.
          </Typography>

          <Typography variant='h5' gutterBottom>
            Use of Google User Data
          </Typography>
          <Typography paragraph>
            Our application uses Google OAuth to authenticate users. We access
            only your primary Google Account email address and your public
            profile information, specifically your profile picture, with your
            explicit consent. This access is used solely for the purpose of
            personalizing your user experience in our app by displaying your
            profile picture and using your email for account identification
            purposes. We do not use this data for any other purpose, nor do we
            process it in any way unrelated to these stated purposes.
          </Typography>
          <Typography variant='h5' gutterBottom>
            Data Storage and Security
          </Typography>
          <Typography paragraph>
            We implement a variety of security measures to maintain the safety
            of your personal information when you place an order or enter,
            submit, or access your personal information. These measures include
            password-protected directories and databases to safeguard your
            information, SSL technology to ensure that your information is fully
            encrypted and sent across the Internet securely.
            <br />
            <br />
            Google User Data Storage:
            <br />
            The email address obtained from your Google Account is stored in our
            cloud-based server. This information is retained to facilitate
            account management and user identification within our application.
            We take stringent security measures to protect your personal
            information and ensure the integrity and confidentiality of your
            data.
          </Typography>
          <Typography variant='h5' gutterBottom>
            User Rights and Choices
          </Typography>
          <Typography paragraph>
            You may choose to restrict the collection or use of your personal
            information in the following way:
            <br />
            If you have previously agreed to us using your personal information
            for direct marketing purposes, you may change your mind at any time
            by writing to or emailing us at help@smart-build-ai.com. You have
            the right to access, update, or delete your personal information,
            including any data obtained from your Google Account, at any time.
            If you wish to exercise these rights or withdraw your consent for us
            to access your Google user data, please contact us directly.
          </Typography>
          <Typography variant='h5' gutterBottom>
            Children’s Privacy
          </Typography>
          <Typography paragraph>
            Our service does not address anyone under the age of 13. We do not
            knowingly collect personal identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do necessary actions.
          </Typography>
          <Typography variant='h5' gutterBottom>
            Cookies and Tracking Technologies
          </Typography>
          <Typography paragraph>
            Our website uses cookies to enhance your experience, gather general
            visitor information, and track visits to our website. If you prefer,
            you can choose to have your computer warn you each time a cookie is
            being sent, or you can choose to turn off all cookies via your
            browser settings. However, like most websites, if you turn your
            cookies off, some of our services may not function properly.
          </Typography>
          <Typography variant='h5' gutterBottom>
            Links to Other Websites
          </Typography>
          <Typography paragraph>
            Our site may contain links to other websites of interest. However,
            once you have used these links to leave our site, you should note
            that we do not have any control over that other website. Therefore,
            we cannot be responsible for the protection and privacy of any
            information which you provide whilst visiting such sites and such
            sites are not governed by this privacy statement. You should
            exercise caution and look at the privacy statement applicable to the
            website in question.
          </Typography>
          <Typography variant='h5' gutterBottom>
            Privacy Policy Changes
          </Typography>
          <Typography paragraph>
            Smart Build AI Inc. reserves the right to update this privacy policy
            at any time. When we do, we will revise the updated date at the top
            of this page. We encourage Users to frequently check this page for
            any changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of modifications. Any changes to how we access,
            use, store, or share Google user data will be reflected in this
            Privacy Policy. Your continued use of our application after any
            change in this Privacy Policy will constitute your acceptance of
            such change.
          </Typography>
          <Typography variant='h5' gutterBottom>
            Contact Information
          </Typography>
          <Typography paragraph>
            If you have any questions regarding this Privacy Policy, the
            practices of this site, or your dealings with this site, please
            contact us at:
          </Typography>
          <Typography paragraph>
            Smart Build AI Inc.
            <Link href='mailto:smart-build-ai.com'>
              help@smart-build-ai.com
            </Link>
            <br />
          </Typography>
          <br />
          {user && (
            <AcceptButton
              onClick={() => dispatch(acceptPrivacyPolicyRequest())}
            >
              Accept
            </AcceptButton>
          )}
        </Paper>
      )}
    </Container>
  );
};

export default PrivacyPolicyPage;
