import { FutureProduct, WaitlistUser } from '../models/WaitlistModels';

export enum WaitlistActionTypes {
  FETCH_FUTURE_PRODUCTS_REQUEST = 'FETCH_FUTURE_PRODUCTS_REQUEST',
  FETCH_FUTURE_PRODUCTS_SUCCESS = 'FETCH_FUTURE_PRODUCTS_SUCCESS',
  FETCH_FUTURE_PRODUCTS_FAILURE = 'FETCH_FUTURE_PRODUCTS_FAILURE',

  FETCH_WAITLIST_USERS_REQUEST = 'FETCH_WAITLIST_USERS_REQUEST',
  FETCH_WAITLIST_USERS_SUCCESS = 'FETCH_WAITLIST_USERS_SUCCESS',
  FETCH_WAITLIST_USERS_FAILURE = 'FETCH_WAITLIST_USERS_FAILURE',

  ADD_WAITLIST_USER_REQUEST = 'ADD_WAITLIST_USER_REQUEST',
  ADD_WAITLIST_USER_SUCCESS = 'ADD_WAITLIST_USER_SUCCESS',
  ADD_WAITLIST_USER_FAILURE = 'ADD_WAITLIST_USER_FAILURE',

  LOGOUT = 'LOGOUT',
}

export interface WaitlistAction {
  type: WaitlistActionTypes;
  payload?: any;
}

export const fetchFutureProductsRequest = () => ({
  type: WaitlistActionTypes.FETCH_FUTURE_PRODUCTS_REQUEST,
});

export const fetchFutureProductsSuccess = (products: FutureProduct[]) => ({
  type: WaitlistActionTypes.FETCH_FUTURE_PRODUCTS_SUCCESS,
  payload: products,
});

export const fetchFutureProductsFailure = () => ({
  type: WaitlistActionTypes.FETCH_FUTURE_PRODUCTS_FAILURE,
});

export const fetchWaitlistUsersRequest = () => ({
  type: WaitlistActionTypes.FETCH_WAITLIST_USERS_REQUEST,
});

export const fetchWaitlistUsersSuccess = (waitlistUsers: WaitlistUser[]) => ({
  type: WaitlistActionTypes.FETCH_WAITLIST_USERS_SUCCESS,
  payload: waitlistUsers,
});

export const fetchWaitlistUsersFailure = () => ({
  type: WaitlistActionTypes.FETCH_WAITLIST_USERS_FAILURE,
});

export const addWaitlistUserRequest = (productId: number, reason: string) => ({
  type: WaitlistActionTypes.ADD_WAITLIST_USER_REQUEST,
  payload: { productId, reason },
});

export const addWaitlistUserSuccess = (waitlistUsers: WaitlistUser[]) => ({
  type: WaitlistActionTypes.ADD_WAITLIST_USER_SUCCESS,
  payload: waitlistUsers,
});

export const addWaitlistUserFailure = () => ({
  type: WaitlistActionTypes.ADD_WAITLIST_USER_FAILURE,
});
