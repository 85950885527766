import { put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchCountriesSuccess,
  fetchCountriesFailure,
  MiscActionTypes,
} from '../actions/miscActions';
import { getCountries } from '../api/miscApi';
import { callGenerator } from './sagaUtils';
import { RootState } from '..';

function* fetchCountriesSaga() {
  const token: string = yield select((state: RootState) => state.user.token);
  try {
    const response = yield* callGenerator(getCountries, token);
    yield put(fetchCountriesSuccess(response.data));
  } catch (error) {
    yield put(fetchCountriesFailure());
    console.error('Fetch Future Products Error:', error);
  }
}

function* countrySaga() {
  yield takeLatest(MiscActionTypes.FETCH_COUNTRIES_REQUEST, fetchCountriesSaga);
}

export default countrySaga;
