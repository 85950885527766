import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import EnterpriseLoginForm from 'src/forms/enterprise/EnterpriseLoginForm';
import { maxPageWidth, responsiveMaxPageWidth } from 'src/styles/constants';
import { RootState } from 'src/store';

const StyledPageContainer = styled.div`
  width: 100%;
  max-width: ${maxPageWidth}px;
  margin: 3rem auto;

  ${responsiveMaxPageWidth}
`;

const EnterpriseLoginPage = () => {
  const navigate = useNavigate();

  const token = useSelector((state: RootState) => state.user.token);

  useEffect(() => {
    if (token) {
      navigate('/create-translation');
    }
  }, [navigate, token]);

  return (
    <StyledPageContainer>
      <EnterpriseLoginForm />
    </StyledPageContainer>
  );
};

export default EnterpriseLoginPage;
