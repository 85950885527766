import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';

import {
  backgroundColor1,
  backgroundColor2,
  textColorSecondary,
  primaryColor1,
  responsiveMaxPageWidth,
  maxPageWidth,
  FlexColumn,
  FlexRow,
} from '../../styles/constants';
import Footer from '../../components/Footer';
import {
  fetchFutureProductsRequest,
  fetchWaitlistUsersRequest,
} from '../../store/actions/waitlistActions';
import { TranslationActionTypes } from '../../store/actions/translationActions';
import { RootState } from '../../store';
import Waitlists from './Waitlists';
import { Success } from '../../components';
import useWindowSize from '../../hooks/useScreenSize';

const StyledPageSectionContainer = styled.div<{
  backgroundColor: string;
  styleOverrides?: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.styleOverrides};
`;

const StyledPageSectionContent = styled.div`
  width: 100%;
  max-width: ${maxPageWidth}px;
  margin: 0 auto;
  padding: 1rem 1rem 0 1rem;

  ${responsiveMaxPageWidth}
`;

const SuccessfulTranslationPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  let imageReductionFactor = 1;

  if (width < 450) {
    imageReductionFactor = 0.0;
  } else if (width < 550) {
    imageReductionFactor = 0.4;
  } else if (width < 700) {
    imageReductionFactor = 0.55;
  } else if (width < 1000) {
    imageReductionFactor = 0.7;
  }

  const {
    waitlistUsers,
    futureProducts,
    fetchFutureProducts,
    fetchWaitlistUsers,
  } = useSelector((state: RootState) => state.waitlists);
  useEffect(() => {
    dispatch({ type: TranslationActionTypes.RESET_TRANSLATION_STATE });
    dispatch(fetchFutureProductsRequest());
    dispatch(fetchWaitlistUsersRequest());
  }, [dispatch]);

  return (
    <>
      <StyledPageSectionContainer
        styleOverrides='border-bottom-right-radius: 3000px;'
        backgroundColor={backgroundColor1}
      >
        <StyledPageSectionContent>
          <FlexRow
            addStyles='align-items: center; margin-left: 3rem; margin-right: 3rem; margin-top: 2rem;'
            id='flex-col'
          >
            <FlexColumn>
              <Success
                message={t('Payment Success!')}
                variant={width < 700 ? 'h5' : 'h4'}
              />
              <br />
              <br />
              <Typography variant='body1' color={textColorSecondary}>
                {t(
                  "Thank you for choosing Trash Bandit Translations. You're faith in us means a lot."
                )}
              </Typography>

              <br />
              <Typography variant='body1' color={textColorSecondary}>
                {t(
                  'Serious work is underway and your document translation will be emailed to you shortly.'
                )}
              </Typography>
              <br />
            </FlexColumn>
            <img
              src='/success.jpg'
              alt='Robotic bunnies working in an office'
              style={{
                width: `${imageReductionFactor * 600}px`, // Adjust width as needed
                height: `${imageReductionFactor * 600}px`, // Height should be the same as width to create a perfect circle
                borderRadius: '50%', // This makes the image round
                objectFit: 'cover', // This resizes the image to cover the area without being stretched
                objectPosition: 'center',
                marginLeft: '2rem',
              }}
            />
          </FlexRow>
          <br />
          <br />
          <br />
        </StyledPageSectionContent>
      </StyledPageSectionContainer>

      {/* Advertisement Section */}
      <StyledPageSectionContainer backgroundColor={backgroundColor2}>
        <br />
        <br />
        <br />
        <StyledPageSectionContent>
          <FlexRow addStyles='margin-bottom: 2rem;'>
            <Typography
              variant={width < 700 ? 'h5' : 'h4'}
              color={primaryColor1}
            >
              {t('We have more products coming!')}
            </Typography>
            <br />
            <br />
          </FlexRow>
          <Waitlists
            waitlistUsers={waitlistUsers}
            futureProducts={futureProducts}
            fetchFutureProducts={fetchFutureProducts}
            fetchWaitlistUsers={fetchWaitlistUsers}
          />
        </StyledPageSectionContent>
        <br />
        <br />
        <br />
      </StyledPageSectionContainer>
      <Footer />
    </>
  );
};

export default SuccessfulTranslationPage;
