import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

const useForceAgreements = (): void => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    // Add or remove paths as needed
    const enforceAgreementPaths = ['/create-translation'];

    // Check if the current path is in the list
    const shouldEnforceAgreement = enforceAgreementPaths.includes(
      location.pathname
    );

    if (user && shouldEnforceAgreement) {
      // Redirect based on missing agreements
      if (!user.isPrivacyPolicyAccepted) {
        navigate('/privacy-policy');
      } else if (!user.isTermsOfServiceAccepted) {
        navigate('/terms-of-service');
      }
    }
    // Add user and location.pathname to the dependency array to rerun the effect when they change
  }, [user, navigate, location.pathname]);
};

export default useForceAgreements;
