import React from 'react';
import styled from '@emotion/styled';

import { ExpandingContentList } from '../../components';
import {
  backgroundColor2,
  maxPageWidth,
  responsiveMaxPageWidth,
} from '../../styles/constants';

interface StyledPageSectionContainerProps {
  backgroundColor: string;
  styleOverrides?: string;
}

const StyledPageSectionContainer = styled.div<StyledPageSectionContainerProps>`
  margin-top: 2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  ${(props) => props.styleOverrides};
`;

const StyledPageSectionContent = styled.div`
  width: 100%;
  max-width: ${maxPageWidth}px;
  margin: 0 auto;
  padding: 1rem;

  ${responsiveMaxPageWidth}
`;

const faqItems = [
  {
    title: 'What is a Machine Translation? (MT)',
    description:
      'In the context of our services, Machine translation is the process of translating document from one language to another by a computer, with no human involvement.',
  },
  {
    title: 'What are the benefits of MT?',
    description:
      'Ready in just minutes and highly cost-effective, our service preserves the original formatting and ensures elements like font colors, data tables, company logos, and more retain their appearance in the translated document. This feature is particularly beneficial for documents containing tables, charts, and other visual data, such as user manuals, technical documents, and financial reports, maintaining their clarity and effectiveness across languages.',
  },
  {
    title: 'What are the limitations of MT?',
    description:
      'Machine Translations (MT) are unable to process text embedded within images. Many PDFs consist solely of image-based text, rendering these documents incompatible with MT services. To determine if a document is suitable for MT, attempt to copy and paste the text. Successful copy/paste actions indicate that the document should be compatible with Machine Translation technologies.',
  },
  {
    title: 'How can AI help in this process?',
    description:
      'AI can analyse the document and make suggestions to improve the quality by taking into account the overall context of the document.',
  },
  // {
  //   title: 'What is meant by Advanced AI Review? (AAR)',
  //   description:
  //     'Upon completion of the Machine Translation (MT) process, our Advanced AI conducts a thorough review of the original document alongside the translation. This AI, with its enhanced contextual understanding, pinpoints and amends inaccuracies or overly literal translations that may have eluded the MT. By doing so, it ensures the final translation maintains the nuanced context of the original text.',
  // },
  // {
  //   title: 'What are the benefits of AAR?',
  //   description:
  //     'The AI excels in grasping and translating the context of a document, adeptly navigating areas where Machine Translation (MT) might adhere too rigidly to word-for-word translations. Through the AI Review process, it discerns the subtle intricacies of language use, even within highly specialized domains. Remarkably, the AI can also identify and compensate for errors present in the original source document, ensuring a higher degree of accuracy and coherence in the final translation.',
  // },
  // {
  //   title: 'What are the limitations of AAR?',
  //   description:
  //     'The AI reviews the source document alongside the translation and proposes edits but cannot directly modify the document. In the Advanced AI Review Tier, we incorporate these recommendations into a second pass through Machine Translation, effectively resolving most issues. However, should Machine Translation fail to address a particular edit, this tier includes an Excel file outlining such instances. This enables users to manually refine the final document, achieving or surpassing the quality of human translation at a significantly reduced cost.',
  // },
];

const FaqPage: React.FC = () => {
  return (
    <StyledPageSectionContainer backgroundColor={backgroundColor2}>
      <StyledPageSectionContent>
        <ExpandingContentList header='FAQ' listItems={faqItems} />
      </StyledPageSectionContent>
    </StyledPageSectionContainer>
  );
};

export default FaqPage;
