import {
  WaitlistAction,
  WaitlistActionTypes,
} from '../actions/waitlistActions';
import { FutureProduct, WaitlistUser } from '../models/WaitlistModels';

interface WaitlistState {
  futureProducts: FutureProduct[];
  waitlistUsers: WaitlistUser[];

  fetchFutureProducts: {
    loading: boolean;
    success: boolean | undefined;
  };
  fetchWaitlistUsers: {
    loading: boolean;
    success: boolean | undefined;
  };
  addWaitlistUser: {
    loading: boolean;
    success: boolean | undefined;
  };
}

const initialState = {
  futureProducts: [],
  waitlistUsers: [],

  fetchFutureProducts: {
    loading: false,
    success: undefined,
  },
  fetchWaitlistUsers: {
    loading: false,
    success: undefined,
  },
  addWaitlistUser: {
    loading: false,
    success: undefined,
  },
};

function waitlistReducer(
  state = initialState,
  action: WaitlistAction
): WaitlistState {
  switch (action.type) {
    case WaitlistActionTypes.FETCH_FUTURE_PRODUCTS_REQUEST:
      return {
        ...state,
        fetchFutureProducts: { ...state.fetchFutureProducts, loading: true },
      };
    case WaitlistActionTypes.FETCH_FUTURE_PRODUCTS_SUCCESS:
      return {
        ...state,
        futureProducts: action.payload,
        fetchFutureProducts: { loading: false, success: true },
      };
    case WaitlistActionTypes.FETCH_FUTURE_PRODUCTS_FAILURE:
      return {
        ...state,
        fetchFutureProducts: { loading: false, success: false },
      };
    case WaitlistActionTypes.FETCH_WAITLIST_USERS_REQUEST:
      return {
        ...state,
        fetchWaitlistUsers: { ...state.fetchWaitlistUsers, loading: true },
      };
    case WaitlistActionTypes.FETCH_WAITLIST_USERS_SUCCESS:
      return {
        ...state,
        waitlistUsers: action.payload,
        fetchWaitlistUsers: { loading: false, success: true },
      };
    case WaitlistActionTypes.FETCH_WAITLIST_USERS_FAILURE:
      return {
        ...state,
        fetchWaitlistUsers: { loading: false, success: false },
      };
    case WaitlistActionTypes.ADD_WAITLIST_USER_REQUEST:
      return {
        ...state,
        addWaitlistUser: { ...state.addWaitlistUser, loading: true },
      };
    case WaitlistActionTypes.ADD_WAITLIST_USER_SUCCESS:
      return {
        ...state,
        waitlistUsers: action.payload,
        addWaitlistUser: { loading: false, success: true },
      };
    case WaitlistActionTypes.ADD_WAITLIST_USER_FAILURE:
      return {
        ...state,
        addWaitlistUser: { loading: false, success: false },
      };
    case WaitlistActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default waitlistReducer;
