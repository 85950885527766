import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import styled from '@emotion/styled';

import {
  ContactUsPage,
  GoogleOAuth2RedirectPage,
  JoinWaitlistPage,
  LandingPage,
  SuccessfulTranslationPage,
  FaqPage,
  EnterpriseLoginPage,
  EnterpriseRegisterPage,
} from './pages';
import { LoadingSpinner, Navbar } from './components';
import { backgroundColor2, primaryColor2 } from './styles/constants';
import CreateTranslationPage from './pages/CreateTranslationPage/CreateTranslationPage';
import { useDimensions } from './hooks';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import useForceAgreements from './hooks/useForceAgreements';
import TermsOfServicePage from './pages/TermsOfServicePage';
import { Typography } from '@mui/material';
import { fetchCountriesRequest } from './store/actions/miscActions';
import './index.css';

const GOOGLE_CLIENT_ID =
  '742130881631-usju32s8hfig34n29gvip62sqqr5pq8c.apps.googleusercontent.com';

let baseUrl: string | undefined;
if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://localhost:3000';
} else {
  // TODO figure out which, won't work for staging for now
  baseUrl = 'https://trash-bandit-translations.com';
}

const REDIRECT_URI = `${baseUrl}/oauth2callback`;

const handleGoogleLogin = () => {
  const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${encodeURIComponent(
    GOOGLE_CLIENT_ID
  )}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=email`;

  // Redirect the user to the Google Auth URL
  window.location.href = googleAuthUrl;
};

const StyledAppBackground = styled.div`
  background-color: ${backgroundColor2};
  min-height: 100vh;
`;

const ComingSoon = () => (
  <Typography variant='h4' color={primaryColor2}>
    Coming soon
  </Typography>
);

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'LOGOUT' });
  }, [dispatch]);

  return <LoadingSpinner size='xl' />;
};

const App = () => {
  const [navbarRef, navbarDimensions] = useDimensions();
  const dispatch = useDispatch();

  useForceAgreements();

  useEffect(() => {
    dispatch(fetchCountriesRequest());
  }, [dispatch]);

  return (
    <StyledAppBackground>
      <Navbar handleGoogleLogin={handleGoogleLogin} ref={navbarRef} />
      <Routes>
        <Route
          path='/'
          element={<LandingPage handleGoogleLogin={handleGoogleLogin} />}
        />
        <Route path='/oauth2callback' element={<GoogleOAuth2RedirectPage />} />
        <Route
          path='/create-translation'
          element={
            <CreateTranslationPage navbarHeight={navbarDimensions.height} />
          }
        />
        <Route
          path='/create-translation/success'
          element={<SuccessfulTranslationPage />}
        />

        <Route
          path='/join-waitlist/product/:productId/:productLabel'
          element={<JoinWaitlistPage />}
        />
        <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
        <Route path='/terms-of-service' element={<TermsOfServicePage />} />

        <Route path='/faq' element={<FaqPage />} />
        <Route path='/help' element={<ComingSoon />} />
        <Route path='/contact-us' element={<ContactUsPage />} />

        <Route path='/logout' element={<Logout />} />

        <Route path='/enterprise/login' element={<EnterpriseLoginPage />} />
        <Route
          path='/enterprise/register'
          element={<EnterpriseRegisterPage />}
        />
      </Routes>
    </StyledAppBackground>
  );
};

export default App;
